import { ComponentProps } from "react";

import { Flex, VStack } from "@chakra-ui/react";

import { HeaderLayout, Layout } from ".";
import { BackButton, Footer } from "..";
import { useIsMobile } from "../../lib/media-query";

export const BackButtonLayout: Layout<ComponentProps<typeof HeaderLayout>> = ({
  children,
  layout: { previousRoute },
}) => {
  const isMobile = useIsMobile();

  return (
    <Flex direction="column" flexGrow={1} align="flex-start">
      {isMobile || <BackButton previousRoute={previousRoute} marginBottom={6} />}
      <VStack align="flex-start" width="full" spacing={6}>
        {children}
      </VStack>
      <Footer marginTop={8} marginBottom={{ base: 4, md: 0 }} />
    </Flex>
  );
};

BackButtonLayout.getLayout = (page, props) =>
  HeaderLayout.getLayout!(<BackButtonLayout {...props}>{page}</BackButtonLayout>, props);
