import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { HStack, IconButton, StackProps, Text } from "@chakra-ui/react";

export const AmountCounter = ({
  amount,
  rootProps,
  isIncreaseDisabled = false,
  isReduceDisabled = false,
  readOnly = false,
  onReduceAmount,
  onIncreaseAmount,
}: {
  amount: number;
  rootProps?: StackProps;
  isIncreaseDisabled?: boolean;
  isReduceDisabled?: boolean;
  readOnly?: boolean;
  onIncreaseAmount: () => void;
  onReduceAmount: () => void;
}) => {
  return (
    <HStack spacing={1} align="center" {...rootProps}>
      {!readOnly && (
        <IconButton
          color="primary.500"
          variant="ghost"
          size="sm"
          aria-label="Diminuir quantidade"
          icon={<MinusIcon />}
          onClick={onReduceAmount}
          isDisabled={isReduceDisabled}
        />
      )}
      <Text color="text.900">{amount}</Text>
      {!readOnly && (
        <IconButton
          color="primary.500"
          variant="ghost"
          size="sm"
          aria-label="Aumentar quantidade"
          icon={<AddIcon />}
          onClick={onIncreaseAmount}
          isDisabled={isIncreaseDisabled}
        />
      )}
    </HStack>
  );
};
