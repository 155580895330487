import { ReactNode, useRef } from "react";

import { BoxProps, HStack, Radio, RadioProps, useColorModeValue } from "@chakra-ui/react";

export const RadioBlock = ({
  value,
  label,
  rootProps,
  radioProps,
  rightNode,
}: {
  value?: string;
  label: string;
  rootProps?: BoxProps;
  radioProps?: RadioProps;
  rightNode?: ReactNode;
}) => {
  const ref = useRef<null | HTMLInputElement>(null);
  const checked = ref.current?.checked;

  const bgColor = useColorModeValue("primary.50", "primary.100");
  const borderColor = useColorModeValue("primary.100", "primary.200");
  const hoverBorderColor = useColorModeValue("gray.300", "whiteAlpha.300");

  return (
    <HStack
      borderColor={useColorModeValue("gray.200", "whiteAlpha.200")}
      borderStyle="solid"
      borderWidth="0.063rem"
      borderRadius="base"
      transitionDuration="0.2s"
      _hover={{ borderColor: checked ? borderColor : hoverBorderColor }}
      padding="4"
      {...(checked && { bgColor, borderColor, color: "primary.500" })}
      {...rootProps}
    >
      <Radio value={value} {...radioProps} ref={ref} w="full" cursor="pointer">
        {label}
      </Radio>
      {rightNode}
    </HStack>
  );
};
