import React, { Fragment, ReactNode, useMemo } from "react";

import { Box, BoxProps, Divider, Flex, HStack, Heading, Text, VStack } from "@chakra-ui/react";

import b from "bigjs-literal";
import Link from "next/link";
import { groupBy, values } from "remeda";

import { InfoCell, InfoCellList, LinkButton } from "..";
import { CreditCard, PaymentMethod } from "../../graphql/generated/apolloHooks";
import {
  Address as AddressType,
  AppointmentService,
  Clinic,
} from "../../graphql/generated/typedDocumentNodes";
import { formatDate, parseDate } from "../../models/date";
import { formatMoney } from "../../models/money";
import { ROUTES } from "../../models/router";
import { Card } from "../Card";
import { Price } from "../Price";
import { ProductDescription } from "../Product/Description";
import { PromoCode, PromoCodeInput } from "./PromoCode";

type SummaryAddress = Omit<AddressType, "__typename" | "geoLocation">;
type AddressProps = {
  address: SummaryAddress;
};

const Address = ({ address }: AddressProps) => {
  const { street, number, district, city, state } = address;

  return (
    <InfoCellList mt={4} w="full" justify="space-between">
      <InfoCell
        spacing="1"
        title="Endereço"
        description={
          <>
            <p>{[street, number].filter(Boolean).join(", ")}</p>
            {district && <p>{district}</p>}
            <p>{[city, state].filter(Boolean).join(" - ")}</p>
          </>
        }
      />
    </InfoCellList>
  );
};

type OrderSummaryProps = {
  service: AppointmentService;
  items: Array<{
    product: any;
    price: string;
    patient?: { id: string | number; name: string };
  }>;
  discounts?: string;
  fees?: string;
  total: string;
  groupBy?: "patients" | "products";
  promoCode?: string;
  showPromocode?: boolean;
  creditCard?: CreditCard | null;
  paymentMethod?: PaymentMethod | null;
  showEditLinks?: boolean;
};

const ItemsByPatient = ({ items }: { items: OrderSummaryProps["items"] }) => {
  const grouped = useMemo(
    () =>
      values(groupBy(items, item => item.patient?.id ?? "empty")).map(patientServices => ({
        patient: patientServices[0].patient,
        services: values(groupBy(patientServices, service => service.product.id)).map(services => ({
          amount: services.length,
          product: services[0].product,
          price: services[0].price,
        })),
      })),
    [items]
  );

  return (
    <VStack alignItems="stretch" spacing={4} width="full">
      {grouped.map(({ patient, services }) => (
        <Fragment key={patient?.id ?? "empty"}>
          <Divider />

          <VStack alignItems="stretch">
            <Text fontWeight="medium" fontSize="lg">
              {patient?.name ?? "Sem paciente associado"}
            </Text>

            {services.map(({ product, price }) => (
              <HStack alignItems="flex-start" justifyContent="space-between" key={product.id}>
                <VStack alignItems="flex-start">
                  <Text color="text.900">{product.name}</Text>
                  <ProductDescription product={product}>
                    {description => (
                      <Text color="text.700" fontSize="sm">
                        {description}
                      </Text>
                    )}
                  </ProductDescription>
                </VStack>
                <Text color="text.900">{formatMoney(price)}</Text>
              </HStack>
            ))}
          </VStack>
        </Fragment>
      ))}
    </VStack>
  );
};

const ItemsByProduct = ({ items }: { items: OrderSummaryProps["items"] }) => {
  const grouped = useMemo(
    () =>
      values(groupBy(items, item => item.product.id)).map(productItems => ({
        product: productItems[0].product,
        quantity: productItems.length,
        total: productItems
          .reduce((total, product) => b`${total} + ${product.price}`, b`0`)
          .toFixed(2),
      })),
    [items]
  );

  return (
    <VStack alignItems="stretch" spacing={4} width="full">
      {grouped.map(({ product, quantity, total }) => (
        <HStack alignItems="flex-start" justifyContent="space-between" key={product.id}>
          <VStack alignItems="flex-start" flex="1">
            <Text color="text.900">{product.name}</Text>
            <ProductDescription product={product}>
              {description => (
                <Text color="text.700" fontSize="sm">
                  {description}
                </Text>
              )}
            </ProductDescription>
          </VStack>
          {quantity > 1 && (
            <Text color="text.900" textAlign="right">
              x{quantity}
            </Text>
          )}
          <Text color="text.900" minWidth={24} textAlign="right">
            {formatMoney(total)}
          </Text>
        </HStack>
      ))}
    </VStack>
  );
};

export const OrderSummary = ({
  service,
  items,
  discounts,
  fees,
  total,
  promoCode,
  groupBy = "patients",
  showPromocode = false,
  creditCard,
  paymentMethod,
  showEditLinks,
  ...props
}: OrderSummaryProps & BoxProps) => {
  return (
    <VStack spacing={4} mt={4} alignItems="stretch" width="full" {...props}>
      {groupBy === "patients" && <ItemsByPatient items={items} />}
      {groupBy === "products" && <ItemsByProduct items={items} />}

      <VStack alignItems="flex-end" spacing={4} flex={1} margin={0}>
        <Box flex={1} />

        {discounts && parseFloat(discounts) > 0 && (
          <HStack justifyContent="space-between" width="full">
            <Text color="text.900">Desconto</Text>
            <Text color="text.900" gridColumnStart="span 2" justifySelf="flex-end">
              {formatMoney(discounts)}
            </Text>
          </HStack>
        )}

        {fees && parseFloat(fees) > 0 && (
          <HStack justifyContent="space-between" width="full">
            <Text color="text.900">
              Taxa{service === AppointmentService.HomeVaccination ? " domiciliar" : ""}
            </Text>
            <Text color="text.900" gridColumnStart="span 2" justifySelf="flex-end">
              {formatMoney(fees)}
            </Text>
          </HStack>
        )}

        {showPromocode && (
          <>
            <Divider />
            {promoCode ? <PromoCode /> : <PromoCodeInput />}
          </>
        )}

        <Divider />

        {paymentMethod !== undefined && (
          <HStack justifyContent="space-between" alignItems="flex-start" width="full">
            <Heading fontSize="xl">Método de pagamento</Heading>
            <VStack alignItems="flex-end">
              <Text fontSize="lg">
                {!paymentMethod
                  ? "Não selecionado"
                  : paymentMethod === PaymentMethod.Pix
                  ? "Pix"
                  : `Cartão de Crédito • • • • ${creditCard?.lastDigits}`}
              </Text>
              {showEditLinks ? (
                <Link href={ROUTES.CART.PAYMENT_METHOD} passHref>
                  <LinkButton as="a">{paymentMethod ? "Trocar" : "Escolher"}</LinkButton>
                </Link>
              ) : null}
            </VStack>
          </HStack>
        )}
        <HStack justifyContent="space-between" width="full">
          <Heading fontSize="2xl">Total</Heading>
          <Price
            price={parseFloat(total)}
            horizontal
            rootProps={{ gridColumnStart: "span 2", justifySelf: "flex-end" }}
          />
        </HStack>
      </VStack>
    </VStack>
  );
};

type Props = OrderSummaryProps & {
  title: string;
  address?: SummaryAddress | null;
  heading?: ReactNode;
  footer?: ReactNode;
  clinic?: Pick<Clinic, "shortName" | "address"> | null | undefined;
  date: string;
  time?: string | null | undefined;
  service: AppointmentService;
  promoCode?: string;
  showPromocode?: boolean;
};

export const AppointmentSummary = ({
  title,
  heading,
  footer,
  address,
  date,
  time,
  clinic,
  items,
  service,
  total,
  discounts,
  fees,
  promoCode,
  showPromocode,
  paymentMethod,
  creditCard,
  showEditLinks,
}: Props) => {
  const timeDescription = useMemo(() => {
    const formattedDate = formatDate(parseDate(date));

    if (!time) return formattedDate;

    const [start, end] = time.includes("--") ? time.split("--") : [time];

    switch (service) {
      case AppointmentService.HomeVaccination: {
        return `${formattedDate} entre ${start} e ${end}`;
      }
      case AppointmentService.ClinicVaccination: {
        return `${formattedDate} às ${start}`;
      }
    }
  }, [date, time, service]);

  return (
    <Flex direction="column" w="full">
      <Card>
        <Heading as="h1" fontSize="3xl">
          {title}
        </Heading>

        <>{heading}</>

        {service === AppointmentService.HomeVaccination && (
          <Text fontWeight="medium" fontSize="lg" mt="6">
            Atendimento domiciliar
          </Text>
        )}

        {service === AppointmentService.ClinicVaccination && (
          <InfoCellList mt={4} w="full" justify="space-between">
            <InfoCell title="Atendimento na clínica" description={clinic?.shortName} />
          </InfoCellList>
        )}

        {address && service === AppointmentService.HomeVaccination && <Address address={address} />}

        {clinic?.address && service === AppointmentService.ClinicVaccination && (
          <Address address={clinic.address} />
        )}

        <InfoCellList mt={4} w="full" justify="space-between">
          <InfoCell title="Horário" description={timeDescription} />
        </InfoCellList>

        <OrderSummary
          service={service}
          items={items}
          discounts={discounts}
          fees={fees}
          total={total}
          promoCode={promoCode}
          showPromocode={showPromocode}
          paymentMethod={paymentMethod}
          creditCard={creditCard}
          showEditLinks={showEditLinks}
        />
      </Card>

      <>{footer}</>
    </Flex>
  );
};
