import { useCallback, useRef, useState } from "react";

type Options = {
  onError?: {
    ({
      input,
      error: { status, message },
    }: {
      input: string;
      error: {
        status: Omit<
          google.maps.places.PlacesServiceStatus,
          google.maps.places.PlacesServiceStatus.OK
        >;
        message: string;
      };
    }): unknown;
    ({
      error,
    }: {
      error: Omit<
        google.maps.places.PlacesServiceStatus,
        google.maps.places.PlacesServiceStatus.OK
      >;
    }): unknown;
  };
  onSearchBegin?: ({ input }: { input: string }) => unknown;
  onPredictions?: ({
    input,
    predictions,
  }: {
    input: string;
    predictions: google.maps.places.AutocompletePrediction[];
  }) => unknown;
  onPlaceDetails?: (place: google.maps.places.PlaceResult) => unknown;
};
export const useGooglePlacesAutocomplete = ({
  onError,
  onSearchBegin,
  onPredictions,
  onPlaceDetails,
}: Options = {}) => {
  const autocompleteRef = useRef<undefined | google.maps.places.AutocompleteService>();
  const sessionTokenRef = useRef<undefined | google.maps.places.AutocompleteSessionToken>();
  const placesServiceRef = useRef<undefined | google.maps.places.PlacesService>();
  const geocoderRef = useRef<undefined | google.maps.Geocoder>();
  const [predictions, setPredictions] = useState([] as google.maps.places.AutocompletePrediction[]);
  const [searching, setSearching] = useState(false);
  const [fetchingDetails, setFetchingDetails] = useState(false);
  const attributionRef = useRef<HTMLDivElement | null>(null);

  const reverseGeocode = useCallback(
    async ({ lat, lng }: { lat: number; lng: number }) => {
      if (!window.google || !window.google.maps) return;

      if (!geocoderRef.current) {
        geocoderRef.current = new google.maps.Geocoder();
      }

      const { results } = await geocoderRef.current.geocode({ location: { lat, lng } });
      if (results[0]) return results[0];
    },
    [geocoderRef]
  );

  const search = useCallback(
    ({ input, ...params }: google.maps.places.AutocompletionRequest) => {
      if (!window.google || !window.google.maps) return;

      if (!autocompleteRef.current) {
        autocompleteRef.current = new window.google.maps.places.AutocompleteService();
      }

      if (!sessionTokenRef.current) {
        sessionTokenRef.current = new window.google.maps.places.AutocompleteSessionToken();
      }
      const sessionToken = sessionTokenRef.current;

      setSearching(true);
      onSearchBegin?.({ input });
      return autocompleteRef.current!.getPlacePredictions(
        { input, sessionToken, ...params },
        (result, status) => {
          setSearching(false);
          switch (status) {
            case window.google.maps.places.PlacesServiceStatus.OK: {
              setPredictions(result!);
              onPredictions?.({ input, predictions: result! });
              break;
            }

            case window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS:
              setPredictions([]);
              onPredictions?.({ input, predictions: [] });
              break;

            default: {
              const message = `Google Places getPlacePredictions returned unexpected status: ${status}`;
              console.error(message);
              onError?.({ input, error: { status, message } });
            }
          }
        }
      );
    },
    [autocompleteRef, sessionTokenRef, onError, onPredictions, onSearchBegin]
  );

  const getDetails = useCallback(
    (placeDetailsRequest: google.maps.places.PlaceDetailsRequest) =>
      new Promise<google.maps.places.PlaceResult>((resolve, reject) => {
        if (!window.google || !window.google.maps || !attributionRef.current) return;

        if (!placesServiceRef.current) {
          placesServiceRef.current = new window.google.maps.places.PlacesService(
            attributionRef.current
          );
        }
        const sessionToken = sessionTokenRef.current;

        setFetchingDetails(true);
        placesServiceRef.current!.getDetails(
          { sessionToken, ...placeDetailsRequest },
          (place, status) => {
            setFetchingDetails(false);
            sessionTokenRef.current = undefined;

            switch (status) {
              case window.google.maps.places.PlacesServiceStatus.OK: {
                onPlaceDetails?.(place!);
                resolve(place!);
                break;
              }

              default: {
                console.error("Google Places getDetails returned unexpected status:", status);
                onError?.({ error: status });
                reject(status);
              }
            }
          }
        );
      }),
    [onError, onPlaceDetails, attributionRef, placesServiceRef, sessionTokenRef]
  );

  return {
    search,
    searching,
    fetchingDetails,
    getDetails,
    reverseGeocode,
    predictions,
    registerAttributionRef: attributionRef,
    clearPredictions: () => setPredictions([]),
  };
};

type Coords = { lat: number; lng: number };
type Address = string;
export const useGeocoder = () => {
  const geocoderRef = useRef<undefined | google.maps.Geocoder>();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<google.maps.GeocoderResult[]>([]);

  const geocode = useCallback(
    async (query: Coords | Address) => {
      if (!window.google || !window.google.maps) return;

      if (!geocoderRef.current) {
        geocoderRef.current = new google.maps.Geocoder();
      }

      try {
        setLoading(true);
        const input = typeof query === "string" ? { address: query } : { location: query };
        const result = await geocoderRef.current.geocode({ ...input, region: "br" });
        setResults(result.results);
        return result;
      } finally {
        setLoading(false);
      }
    },
    [geocoderRef]
  );

  return [geocode, { loading, data: results }] as const;
};
