import React, { ComponentProps } from "react";

import { Box, Flex } from "@chakra-ui/react";

import { useRouter } from "next/router";

import {
  ApplicationContextLayout,
  ApplicationContextProps,
  Layout,
  getApplicationContextProps,
} from ".";
import { CartDrawer, Header, MobileHeader, PageLimit } from "..";
import { useIsMobile } from "../../lib/media-query";
import { useChain } from "../../models/chain";
import { ROUTES } from "../../models/router";
import { useValidateImageSrc } from "../../utils";
import { CookiesDrawer } from "../CookiesDrawer";
import { WhatsappLayout } from "./WhatsappLayout";

type HeaderLayoutProps = {
  includeBanner?: boolean;
  disableDefaultPageLimit?: boolean;
  headerProps?: ComponentProps<typeof Box>;
};

export const HeaderLayout: Layout<ApplicationContextProps & HeaderLayoutProps> = ({
  children,
  layout: { previousRoute },
  includeBanner = false,
  disableDefaultPageLimit = false,
  headerProps = {},
}) => {
  const chain = useChain();
  const isMobile = useIsMobile();
  const router = useRouter();

  const { isLoading: loadingImageValidation, hasError: imageIsInvalid } = useValidateImageSrc(
    ROUTES.API.BANNER
  );

  const showBanner = includeBanner && !loadingImageValidation && !imageIsInvalid;
  const showLogo = !showBanner || router.asPath !== ROUTES.INDEX || chain.logo.showInHomePage;

  return (
    <Flex
      className="Shop"
      direction="column"
      minHeight="100vh"
      bgColor="background.secondary"
      paddingBottom={{ base: "30px", md: "0" }}
      paddingTop={{ base: "44px", md: "0" }}
    >
      {isMobile ? (
        <MobileHeader className="Shop__header" chain={chain} previousRoute={previousRoute} />
      ) : (
        <Header
          className="Shop__header"
          chain={chain}
          showBanner={showBanner}
          showLogo={showLogo}
          {...headerProps}
        />
      )}
      {!disableDefaultPageLimit ? (
        <PageLimit>
          <Flex direction="column" flexGrow={1} paddingY={{ base: 10, md: 8 }}>
            {children}
          </Flex>
        </PageLimit>
      ) : (
        children
      )}
      <CartDrawer />
      <CookiesDrawer />
    </Flex>
  );
};

HeaderLayout.getLayout = (page, props) =>
  ApplicationContextLayout.getLayout!(
    WhatsappLayout.getLayout!(<HeaderLayout {...props}>{page}</HeaderLayout>, props),
    props
  );

export const getHeaderProps = async (
  hostname: string | undefined
): Promise<
  | {
      props: ComponentProps<typeof HeaderLayout>;
    }
  | {
      notFound: true;
    }
> => {
  const applicationContextProps = await getApplicationContextProps(hostname);

  if ("notFound" in applicationContextProps) {
    return applicationContextProps;
  }

  return { props: { ...applicationContextProps.props } };
};
