import React, { ComponentProps, useMemo } from "react";

import { Box, chakra, useColorMode, useColorModeValue } from "@chakra-ui/react";

import { format, isValid, parse } from "date-fns";
import ReactDayPicker, { DateUtils } from "react-day-picker";
import ReactDayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { identity, omit } from "remeda";

import { DateMaskInput } from "../MaskedInput";

const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

const weekdaysLong = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];

const weekdaysShort = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];

const parseInputDate = (str: string, dateFormat: string) => {
  const parsed = parse(str, dateFormat, new Date());
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
};

const formatInputDate = (date: Date, dateFormat: string) => format(date, dateFormat);

const CkDayPicker = chakra(ReactDayPicker);
const CkDayPickerInput = chakra(ReactDayPickerInput);

const dayClassName = ".DayPicker-Day";

const DayPicker = ({ sx, ...props }: ComponentProps<typeof CkDayPicker>) => {
  const { colorMode } = useColorMode();
  const isDarkMode = colorMode === "dark";

  return (
    <CkDayPicker
      sx={{
        [dayClassName]: {
          display: "table-cell",
          padding: "0.5em",
          borderRadius: "full",
          verticalAlign: "middle",
          textAlign: "center",
          cursor: "pointer",
          width: "40px",
          height: "40px",
          transition: "ease-in-out 0.15s",
        },
        [`${dayClassName}--today`]: {
          fontWeight: 700,
          color: "primary.500",
        },
        [`${dayClassName}--disabled`]: {
          color: isDarkMode ? "whiteAlpha.300" : "blackAlpha.300",
          cursor: "default",
        },
        [`${dayClassName}--selected:not(${dayClassName}--disabled):not(${dayClassName}--outside)`]:
          {
            position: "relative",
            bgColor: "primary.500",
            color: "white",
            transition: "ease-in-out 0.2s",
            "&:hover": {
              bgColor: "primary.600",
            },
          },
        [`&:not(.interactionDisabled) ${dayClassName}:not(${dayClassName}--disabled):not(${dayClassName}--outside):not(${dayClassName}--selected):hover`]:
          {
            backgroundColor: isDarkMode ? "gray.700" : "gray.100",
          },
        ".DayPicker": { ...sx },
      }}
      {...props}
    />
  );
};

export const DatePicker = (props: ComponentProps<typeof DayPicker>) => (
  <DayPicker
    locale="pt-BR"
    months={months}
    weekdaysLong={weekdaysLong}
    weekdaysShort={weekdaysShort}
    {...props}
  />
);

type Props = Omit<ComponentProps<typeof CkDayPickerInput>, "inputProps" | "format" | "onChange"> & {
  inputProps?: ComponentProps<typeof DateMaskInput>;
  internalFormat?: string;
  displayFormat?: string;
  onChange: (date: string | undefined) => void;
};

const CustomOverlay = ({
  classNames,
  children,
  ...props
}: {
  classNames: any; // Tipagem não definida na lib 'react-day-picker'
  children: React.ReactChild;
  [key: string]: any;
}) => {
  const styledChildren = useMemo(
    () =>
      React.Children.map(children, child =>
        typeof child === "object" ? <DayPicker {...omit(child.props, ["classNames"])} /> : child
      ),
    [children]
  );

  return (
    <Box className={classNames.overlayWrapper} {...props}>
      <Box
        className={classNames.overlay}
        bgColor={useColorModeValue("background.primary", "background.secondary")}
      >
        {styledChildren}
      </Box>
    </Box>
  );
};

export const DatePickerInput = ({
  displayFormat = "dd/MM/yyyy",
  internalFormat = displayFormat,
  placeholder = "dd/mm/aaaa",
  onChange = identity,
  value,
  ...props
}: Props) => {
  const displayValue = useMemo(() => {
    if (typeof value !== "string" || displayFormat === internalFormat) return value;
    const parsedDate = parse(value, internalFormat, new Date());
    return isValid(parsedDate) ? format(parsedDate, displayFormat) : value;
  }, [displayFormat, internalFormat, value]);

  return (
    <CkDayPickerInput
      format={displayFormat}
      formatDate={formatInputDate}
      parseDate={parseInputDate}
      placeholder={placeholder}
      value={displayValue}
      component={(props: Record<string, any>) => <DateMaskInput autoComplete="off" {...props} />}
      dayPickerProps={{ locale: "pt-BR", months, weekdaysLong, weekdaysShort }}
      overlayComponent={CustomOverlay}
      onDayChange={day => onChange(day instanceof Date ? format(day, internalFormat) : undefined)}
      style={{ width: "100%" }}
      {...props}
    />
  );
};
