import { forwardRef } from "react";

import ReactMaskedInput from "react-text-mask";

import { MaskedInput, MaskedInputPropsOptionalMask } from ".";

const cpfMask = [/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/];

export const CPFInput = forwardRef<ReactMaskedInput, MaskedInputPropsOptionalMask>(
  ({ mask, ...props }, ref) => (
    <MaskedInput mask={mask ?? cpfMask} ref={ref} showMask={false} {...props} />
  )
);
