import { forwardRef } from "react";

import ReactMaskedInput from "react-text-mask";

import { MaskedInput, MaskedInputPropsOptionalMask } from ".";

const zipCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/];

export const ZipCodeInput = forwardRef<ReactMaskedInput, MaskedInputPropsOptionalMask>(
  (props, ref) => <MaskedInput mask={zipCodeMask} ref={ref} showMask={false} {...props} />
);
