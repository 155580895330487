import React, { useState } from "react";

import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Stack,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";

import removeAccents from "remove-accents";

import { useUpdateCartPromoCodeMutation } from "../../graphql/generated/apolloHooks";
import { useIsMobile } from "../../lib/media-query";
import { useCartId, useCartPromocode, useFullCart, useUpsertCart } from "../../models/cart";
import { formatMoney } from "../../models/money";
import { LinkButton } from "../Button";

export const PromoCode = () => {
  const { promoCode } = useCartPromocode();

  const bgColorValid = useColorModeValue("gray.100", "whiteAlpha.100");
  const bgColorInvalid = useColorModeValue("red.100", "red.700");

  const colorInvalid = useColorModeValue("red.500", "red.200");

  const [upsertCart, { loading }] = useUpsertCart();

  if (!promoCode) return null;
  const isPromoCodeValid = promoCode.__typename === "PromoCode";

  return (
    <Box
      w="full"
      backgroundColor={isPromoCodeValid ? bgColorValid : bgColorInvalid}
      padding="6"
      borderRadius="md"
    >
      <HStack justifyContent="space-between" width="full">
        <VStack alignItems="flex-start">
          <Text fontWeight="medium" fontSize="lg">
            Código promocional {!isPromoCodeValid && "(Inválido)"}
          </Text>

          <Text color="text.900" textDecor={isPromoCodeValid ? undefined : "line-through"}>
            {promoCode.code}
          </Text>

          <LinkButton
            onClick={() => upsertCart({ variables: { input: { promoCode: "" } } })}
            color={isPromoCodeValid ? "undefined" : colorInvalid}
            isLoading={loading}
          >
            Remover código promocional
          </LinkButton>
        </VStack>

        {isPromoCodeValid && (
          <Text color="text.900" minWidth={24} textAlign="right">
            {formatMoney(promoCode.totalDiscount || 0)}
          </Text>
        )}
      </HStack>
    </Box>
  );
};

export const PromoCodeInput = () => {
  const cartId = useCartId();
  const { refetch: refetchFullCart } = useFullCart();

  const isMobile = useIsMobile();
  const [code, setCode] = useState("");
  const [showInput, setShowInput] = useState(false);

  const [updateCartPromoCode, { data, loading: validating }] = useUpdateCartPromoCodeMutation({
    onCompleted: () => refetchFullCart(),
  });

  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      w="full"
      justifyContent="flex-end"
      alignItems="top"
      spacing={4}
    >
      {isMobile && !showInput && (
        <Button size="lg" w="100%" mt={2} onClick={() => setShowInput(true)}>
          Adicionar código promocional
        </Button>
      )}

      {(!isMobile || showInput) && (
        <>
          <FormControl
            isInvalid={data?.updateCartPromoCode?.__typename === "InvalidPromoCode"}
            width={{ base: "full", md: "30%" }}
          >
            <Input
              placeholder="Código promocional"
              isDisabled={validating}
              marginLeft={isMobile ? "0" : undefined}
              value={code}
              onChange={evt => setCode(removeAccents(evt.target.value).toUpperCase())}
              onKeyPress={evt => {
                if (!removeAccents(evt.key).match(/[a-zA-Z0-9]/)) {
                  evt.stopPropagation();
                  evt.preventDefault();
                }
              }}
              onPaste={evt => {
                const text = evt.clipboardData.getData("Text");
                evt.stopPropagation();
                evt.preventDefault();
                setCode(removeAccents(text).replaceAll(" ", "").toUpperCase());
              }}
            />

            <FormErrorMessage>
              O código promocional informado é inexistente ou não é valido para o seu pedido
            </FormErrorMessage>
          </FormControl>

          <Button
            size="lg"
            disabled={validating}
            onClick={() =>
              updateCartPromoCode({
                variables: {
                  input: {
                    cartId,
                    code,
                  },
                },
              })
            }
          >
            Aplicar código
          </Button>
        </>
      )}
    </Stack>
  );
};
