import { forwardRef } from "react";

import ReactMaskedInput from "react-text-mask";

import { MaskedInput, MaskedInputPropsOptionalMask } from ".";

const creditCardCVVMask = [/\d/, /\d/, /\d/, /\d/];

export const CreditCardCVVInput = forwardRef<ReactMaskedInput, MaskedInputPropsOptionalMask>(
  (props, ref) => (
    <MaskedInput mask={creditCardCVVMask} ref={ref} guide={false} showMask={false} {...props} />
  )
);

const creditCardExpiryDateMask = [/\d/, /\d/, "/", /\d/, /\d/];

export const CreditCardExpiryDateInput = forwardRef<ReactMaskedInput, MaskedInputPropsOptionalMask>(
  (props, ref) => (
    <MaskedInput
      mask={creditCardExpiryDateMask}
      ref={ref}
      guide={false}
      showMask={false}
      {...props}
    />
  )
);

const creditCardMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const CreditCardInput = forwardRef<ReactMaskedInput, MaskedInputPropsOptionalMask>(
  (props, ref) => (
    <MaskedInput mask={creditCardMask} ref={ref} guide={false} showMask={false} {...props} />
  )
);
