import { forwardRef } from "react";

import ReactMaskedInput from "react-text-mask";

import { MaskedInput, MaskedInputPropsOptionalMask } from ".";

const phoneNumberMask = (value: string) => {
  if (value.replace(/\D/g, "").length > 10) {
    return [
      "(",
      /[1-9]/,
      /[1-9]/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  } else {
    return ["(", /[1-9]/, /[1-9]/, ")", " ", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
  }
};

export const PhoneNumberInput = forwardRef<ReactMaskedInput, MaskedInputPropsOptionalMask>(
  (props, ref) => <MaskedInput mask={phoneNumberMask} ref={ref} showMask={false} {...props} />
);
