import { ReactNode, useCallback, useEffect, useRef } from "react";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Stack, StackProps, Text, Theme, useTheme } from "@chakra-ui/react";

import { formatISO, isSameDay } from "date-fns";
import { identity } from "remeda";

import { formatDate, parseDate } from "../../models/date";
import { dropWhile } from "../../utils";

const inVisibleScrollArea = (parent: HTMLElement, item: HTMLElement) => {
  const parentLeft = parent.scrollLeft;
  const parentRight = parentLeft + parent.clientWidth;

  const itemLeft = item.offsetLeft;
  const itemRight = item.clientWidth + itemLeft;

  return itemRight <= parentRight && itemLeft >= parentLeft;
};

type DateSelectorProps = {
  value: string | null;
  dates: string[];
  enabledDates: string[];
  onChange: (newDate: string) => void;
  dateWrapper?: (
    dateNode: ReactNode,
    { date, isEnabled }: { date: string; isEnabled: boolean }
  ) => ReactNode;
} & Omit<StackProps, "onChange">;
export const DateSelector = ({
  dates,
  enabledDates,
  value,
  onChange,
  dateWrapper,
  ...props
}: DateSelectorProps) => {
  const theme = useTheme() as Theme;
  const containerRef = useRef<HTMLDivElement | null>(null);

  const onForward = useCallback(() => {
    const container = containerRef.current;
    if (container) {
      const items = [...container.querySelectorAll("[data-list-item]")] as HTMLElement[];
      const visibleItems = dropWhile(items, i => !inVisibleScrollArea(container, i));
      const target = visibleItems.find(i => !inVisibleScrollArea(container, i));
      if (target) {
        container.scrollTo({ left: target.offsetLeft, behavior: "smooth" });
      }
    }
  }, [containerRef]);

  const onBackward = useCallback(() => {
    const container = containerRef.current;
    if (container) {
      const items = [...container.querySelectorAll("[data-list-item]")] as HTMLElement[];
      const firstVisible = items.findIndex(i => inVisibleScrollArea(container, i));
      if (firstVisible && firstVisible - 1 >= 0) {
        const target = items[firstVisible];
        const left = Math.max(0, target.offsetLeft + target.clientWidth - container.clientWidth);
        container.scrollTo({ left, behavior: "smooth" });
      }
    }
  }, [containerRef]);

  useEffect(() => {
    const container = containerRef.current;
    if (container && value) {
      const target = container.querySelector(
        `[data-list-value='${formatISO(parseDate(value))}']`
      ) as HTMLElement | null;
      if (target && !inVisibleScrollArea(container, target)) {
        const left = Math.max(0, target.offsetLeft - container.clientWidth / 2);
        container.scrollLeft = left;
      }
    }
  }, [value, containerRef]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={4}
      {...props}
    >
      <Box
        ml={2}
        flexShrink={0}
        as={ChevronLeftIcon}
        borderRadius="full"
        w={6}
        h={6}
        bgColor="primary.100"
        color="gray.500"
        mx="0"
        onClick={onBackward}
        cursor="pointer"
      />
      <Stack
        ref={containerRef}
        direction="row"
        overflow="auto"
        style={{ scrollSnapType: "x mandatory", scrollSnapPointsX: `repeat(${theme.sizes[14]})` }}
        spacing={1.5}
        position="relative"
      >
        {dates.map((date, i) => {
          const isEnabled = enabledDates.includes(date);
          const selected = value && isSameDay(parseDate(date), parseDate(value));
          const dateNode = (
            <Stack
              alignItems="flex-start"
              spacing={0.5}
              data-list-item="1"
              data-list-value={formatISO(parseDate(date))}
              key={i}
              rounded="md"
              borderWidth="0.063rem"
              borderColor="gray.300"
              cursor="pointer"
              {...(!isEnabled && {
                bgColor: "gray.100",
                cursor: "not-allowed",
              })}
              {...(selected && {
                bg: "primary.100",
                color: "primary.500",
              })}
              px={2.5}
              py={2}
              style={{ scrollSnapAlign: "start", minWidth: theme.sizes[12] }}
              onClick={isEnabled ? () => onChange(date) : identity}
            >
              <Text fontSize="xs" variant="roboto" color="gray.500" textTransform="capitalize">
                {formatDate(parseDate(date), "MMM")}
              </Text>
              <Text variant="roboto" fontSize="2xl" color={selected ? "inherit" : "gray.800"}>
                {formatDate(parseDate(date), "dd")}
              </Text>
              <Text
                fontSize="sm"
                variant="roboto"
                color={selected ? "inherit" : "gray.500"}
                textTransform="capitalize"
              >
                {formatDate(parseDate(date), "EEEEEE")}
              </Text>
            </Stack>
          );

          return dateWrapper ? dateWrapper(dateNode, { date, isEnabled }) : dateNode;
        })}
      </Stack>
      <Box
        ml={2}
        flexShrink={0}
        as={ChevronRightIcon}
        borderRadius="full"
        w={6}
        h={6}
        bgColor="primary.100"
        color="gray.500"
        mx="0"
        onClick={onForward}
        cursor="pointer"
      />
    </Stack>
  );
};
