import { PropsWithChildren, useCallback } from "react";

import { Box, HStack, Icon, Stack, StackProps, Text, VStack } from "@chakra-ui/react";

import { HiLockClosed } from "react-icons/hi";
import { IoShieldCheckmark } from "react-icons/io5";

import { useAcceptCreditCard, useAcceptPix, useChain } from "../../models/chain";
import { ClinicsDetails } from "../Clinics";
import { AmericanExpressIcon } from "./AmericanExpressIcon";
import { EloIcon } from "./EloIcon";
import { HipercardIcon } from "./HipercardIcon";
import { MastercardIcon } from "./MastercardIcon";
import { PagarmeIcon } from "./PagarmeIcon";
import { PixIcon } from "./PixIcon";
import { VisaIcon } from "./VisaIcon";

type Props = StackProps & { withClinicsDetails?: boolean };

export const Footer = ({ withClinicsDetails = false, ...props }: Props) => {
  const chain = useChain();
  const acceptsPix = useAcceptPix();
  const acceptsCreditCard = useAcceptCreditCard();

  const Wrapper = useCallback(
    ({ children, ...props }: PropsWithChildren<StackProps>) =>
      withClinicsDetails ? (
        <VStack spacing={2} alignItems={{ base: "center", lg: "end" }} {...props}>
          {children}
        </VStack>
      ) : (
        <>{children}</>
      ),
    [withClinicsDetails]
  );

  return (
    <Stack
      direction={{ base: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      width="full"
      spacing={4}
      {...props}
    >
      {withClinicsDetails && (
        <ClinicsDetails alignItems={{ base: "center", lg: "start" }} clinics={chain.clinics} />
      )}

      <Wrapper>
        <Stack
          direction={{ base: "column", lg: "row" }}
          alignItems={{ base: "center", lg: "center" }}
          spacing={{ base: 2, lg: 8 }}
        >
          <HStack>
            <Icon as={HiLockClosed} color="text.700" w={5} h={5} />
            <Box>
              <Text color="text.700" variant="roboto" marginTop="2px">
                Site protegido · 100% seguro
              </Text>
            </Box>
          </HStack>

          <Box>
            <PagarmeIcon marginBottom="2px" h={6} w="auto" />
          </Box>

          <HStack>
            <Icon as={IoShieldCheckmark} color="green.500" w={5} h={5} />
            <Box>
              <Text color="text.900" variant="roboto" marginTop="2px">
                Certificado SSL
              </Text>
            </Box>
          </HStack>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          alignContent="center"
          justifyItems="center"
          spacing={4}
        >
          {acceptsPix && <PixIcon h={6} w="auto" />}

          {acceptsCreditCard && (
            <>
              <MastercardIcon h={10} w="auto" />
              <VisaIcon h={5} w="auto" />
              <EloIcon h={6} w="auto" />
              <AmericanExpressIcon h={6} w="auto" />
              <HipercardIcon h={6} w="auto" />
            </>
          )}
        </Stack>
      </Wrapper>
    </Stack>
  );
};
