// TODO: Tipar corretamente o plugin sectionizer
// @ts-expect-error
import findAfter from "unist-util-find-after";
import visit from "unist-util-visit-parents";

export const sectionizer = () => (tree: any) => {
  const MAX_HEADING_DEPTH = 6;

  const sectionize = (node: any, ancestors: any) => {
    const start = node;
    const parent = ancestors[ancestors.length - 1];

    const isEnd = (node: any) => node.type === "heading" && node.depth <= start.depth;
    const end = (findAfter as any)(parent, start, isEnd);

    const startIndex = parent.children.indexOf(start);
    const endIndex = parent.children.indexOf(end);

    const between = parent.children.slice(startIndex, endIndex > 0 ? endIndex : undefined);

    const section = {
      type: "section",
      children: between,
      position: parent.position,
      data: {
        hName: "section",
      },
    };

    parent.children.splice(startIndex, section.children.length, section);
  };

  for (let depth = MAX_HEADING_DEPTH; depth > 1; depth--) {
    (visit as any)(
      tree,
      (node: any) => node.type === "heading" && node.depth === depth,
      sectionize
    );
  }

  return tree;
};
