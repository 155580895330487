import { Box, Button, Slide, Stack, Text } from "@chakra-ui/react";

import { useLocalStorage } from "react-use";

import { PageLimit } from "..";
import { useAnalytics } from "../../lib/analytics";

export const CookiesDrawer = () => {
  const [closed, setClosed] = useLocalStorage<boolean>("closedCookiesDrawer", false);
  const { loaded } = useAnalytics();

  return (
    <Slide direction="bottom" in={!closed && loaded}>
      <Box backgroundColor="white" boxShadow="baseInverted" paddingY={2}>
        <PageLimit>
          <Stack
            w="full"
            justifyContent="center"
            alignItems="center"
            direction={{ base: "column", md: "row" }}
            spacing={4}
          >
            <Text fontSize="md">
              Ao navegar neste site, você{" "}
              <Text as="span" fontWeight="500">
                aceita
              </Text>{" "}
              que utilizemos cookies para poder melhorar sua experiência.
            </Text>

            <Button onClick={() => setClosed(true)} size="sm">
              Entendi
            </Button>
          </Stack>
        </PageLimit>
      </Box>
    </Slide>
  );
};
