import { Stack, StackProps, Text, TextProps } from "@chakra-ui/react";

import { formatMoney } from "../../models/money";

export const Price = ({
  price,
  discountPrice,
  showPackageBadge,
  rootProps,
  horizontal,
  priceProps,
  formatOptions,
}: {
  price: number;
  discountPrice?: number;
  showPackageBadge?: boolean;
  rootProps?: StackProps;
  horizontal?: boolean;
  priceProps?: TextProps;
  formatOptions?: Parameters<typeof formatMoney>[1];
}) => (
  <Stack
    direction={!horizontal ? "column" : "row"}
    spacing={1}
    justify="flex-end"
    align="flex-end"
    {...rootProps}
  >
    {showPackageBadge && (
      <Text
        bgColor="yellow.100"
        fontSize="xs"
        paddingY={1}
        paddingX={2.5}
        marginBottom={discountPrice ? 2 : 2.5}
        borderRadius="sm"
        color="black"
      >
        PACOTE
      </Text>
    )}
    {discountPrice && discountPrice < price && (
      <Text textDecoration="line-through" color="text.700" fontSize="sm" isTruncated>
        {formatMoney(price, formatOptions)}
      </Text>
    )}
    <Text
      variant="roboto"
      fontSize="2xl"
      fontWeight="medium"
      color="text.900"
      lineHeight={7}
      isTruncated
      {...priceProps}
    >
      {formatMoney(discountPrice ?? price, formatOptions)}
    </Text>
  </Stack>
);
