import { ChevronLeftIcon } from "@chakra-ui/icons";
import { ButtonProps } from "@chakra-ui/react";

import { makeVar, useReactiveVar } from "@apollo/client";
import { NextRouter, useRouter } from "next/router";

import { LinkButton } from ".";

type Url = Parameters<NextRouter["push"]>[0];
const backRouteVar = makeVar(null as Url | null);

export const useBackRoute = () => {
  const previousRoute = useReactiveVar(backRouteVar);
  return [previousRoute, backRouteVar] as const;
};

export const useGoBack = () => {
  const router = useRouter();
  const [storedPreviousRoute, setPreviousRoute] = useBackRoute();

  return (route?: Url) => {
    const previousRoute = route ?? storedPreviousRoute;

    if (previousRoute) {
      router.replace(previousRoute);
      setPreviousRoute(null);
    } else {
      router.back();
    }
  };
};

export const BackButton = ({ previousRoute, ...props }: ButtonProps & { previousRoute?: Url }) => {
  const goBack = useGoBack();
  const onClick = () => goBack(previousRoute);

  return (
    <LinkButton
      fontSize="xl"
      color="text.700"
      leftIcon={<ChevronLeftIcon w={7} h={7} color="text.700" />}
      onClick={onClick}
      textDecoration="none"
      sx={{ "& > span:first-child": { margin: 0 } }}
      _hover={{ textDecoration: "none", color: "text.700" }}
      _active={{ color: "text.700" }}
      {...props}
    >
      Voltar
    </LinkButton>
  );
};
