import React, { Fragment, useCallback } from "react";

import {
  Divider,
  Heading,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  StackProps,
  Text,
  UnorderedList,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";

import { times } from "remeda";

import { ExpandableLinkButton } from "..";
import {
  ChainAndClinicsQuery,
  WeekDayScheduleTimes,
} from "../../graphql/generated/typedDocumentNodes";
import { formatTime, parseTime } from "../../models/date";
import { useArray } from "../../utils";

type Clinics = NonNullable<ChainAndClinicsQuery["chain"]>["clinics"];

export const ClinicsDetailsModal = ({
  isOpen,
  close,
  clinics,
}: {
  isOpen: boolean;
  close: () => void;
  clinics: Clinics;
}) => {
  const { value: openedScheduleDetails, update: updateOpenedScheduleDetails } = useArray(
    times(clinics.length, () => false)
  );

  const toggleOpened = useCallback(
    (index: number) => updateOpenedScheduleDetails(index, open => !open),
    [updateOpenedScheduleDetails]
  );

  return (
    <Modal isOpen={isOpen} onClose={close} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Unidades</ModalHeader>
        <ModalCloseButton />
        <ModalBody mb={4}>
          <VStack w="full" spacing="3">
            {clinics.map((clinic, index) => {
              const {
                address: { city, district, state, street, number, zipCode },
                weekSchedule,
              } = clinic;

              const todaySchedule = weekSchedule[new Date().getDay()];

              const displaySchedule = (schedule?: WeekDayScheduleTimes) =>
                schedule
                  ? `${formatTime(parseTime(schedule.opens))} às ${formatTime(
                      parseTime(schedule.closes)
                    )}`
                  : "Fechado";

              return (
                <Fragment key={index}>
                  {index !== 0 && <Divider w="full" />}
                  <VStack key={index} w="full" align="flex-start" spacing={1}>
                    <Heading size="sm">{clinic.shortName}</Heading>

                    <VStack spacing="0" align="flex-start" w="full">
                      <Text>
                        {[street, number].filter(Boolean).join(", ") +
                          (district ? ` - ${district}` : "")}
                      </Text>
                      <Text>{[city, state].filter(Boolean).join(" - ")}</Text>
                      {zipCode && <Text>CEP: {zipCode}</Text>}
                    </VStack>

                    <Heading size="sm">Horários de funcionamento</Heading>

                    <VStack spacing={1} align="flex-start" w="full">
                      <Text>
                        {`Hoje (${todaySchedule?.weekDay}.): ${displaySchedule(
                          todaySchedule?.schedule ?? undefined
                        )}`}
                      </Text>

                      <ExpandableLinkButton
                        size="sm"
                        isOpen={openedScheduleDetails[index]}
                        onClick={() => toggleOpened(index)}
                        reverseHorizontalAlign
                      >
                        {openedScheduleDetails[index]
                          ? "Ver menos horários"
                          : "Ver todos os horários"}
                      </ExpandableLinkButton>

                      {openedScheduleDetails[index] && (
                        <UnorderedList>
                          {weekSchedule.map(daySchedule => (
                            <ListItem key={`${index}-${daySchedule.weekDay}`} marginLeft={5}>
                              <Text>
                                {`${daySchedule.weekDay}: ${displaySchedule(
                                  daySchedule.schedule ?? undefined
                                )}`}
                              </Text>
                            </ListItem>
                          ))}
                        </UnorderedList>
                      )}
                    </VStack>
                  </VStack>
                </Fragment>
              );
            })}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export const ClinicsDetails = ({
  clinics,
  alignItems = "start",
  ...props
}: StackProps & { clinics: Clinics }) => {
  const modal = useDisclosure();

  return (
    <VStack spacing={1} alignItems={alignItems} {...props}>
      {clinics.map(({ id, address }) => (
        <Text color="text.900" fontSize="sm" key={id} whiteSpace="nowrap">
          {[address.city, address.district].filter(Boolean).join(", ")} ({address.state})
        </Text>
      ))}

      <ExpandableLinkButton alignSelf={alignItems} onClick={modal.onOpen}>
        Endereços e horários
      </ExpandableLinkButton>

      <ClinicsDetailsModal isOpen={modal.isOpen} close={modal.onClose} clinics={clinics} />
    </VStack>
  );
};
