import { forwardRef } from "react";

import { Input as CkInput, InputProps, chakra } from "@chakra-ui/react";

import ReactMaskedInput, { MaskedInputProps as ReactMaskedInputProps } from "react-text-mask";

export type MaskedInputProps = InputProps & ReactMaskedInputProps;

export type MaskedInputPropsOptionalMask = Omit<MaskedInputProps, "mask"> & {
  mask?: MaskedInputProps["mask"];
};

const Input = chakra(CkInput);

export const MaskedInput = chakra(
  forwardRef<ReactMaskedInput, MaskedInputProps>((props, ref) => (
    <ReactMaskedInput
      ref={ref}
      render={(ref, props) => <Input ref={ref} {...props} />}
      {...props}
    />
  ))
);

export * from "./CPFInput";
export * from "./CreditCardInput";
export * from "./DateMaskInput";
export * from "./PhoneNumberInput";
export * from "./ZipCodeInput";
