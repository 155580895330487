import { useCallback, useEffect, useMemo, useRef } from "react";

import { MutationHookOptions, makeVar, useReactiveVar } from "@apollo/client";
import b from "bigjs-literal";
import { useLocalStorage } from "react-use";
import { pick, sortBy } from "remeda";

import {
  CartFragment,
  CartQuery,
  FullCartQuery,
  IncrementCartItemMutation,
  UpsertCartMutation,
  UpsertPatientInput,
  useAssociateCartItemToPatientMutation,
  useCartFieldsForFeeQuery,
  useCartPromoCodeQuery,
  useCartQuery,
  useChainAttendsLocationQuery,
  useChainServicePriceRangeQuery,
  useCheckoutCartMutation,
  useClinicAttendsLocationQuery,
  useFullCartQuery,
  useIncrementCartItemMutation,
  useUpsertCartMutation,
  useUpsertPatientMutation,
} from "../../graphql/generated/apolloHooks";
import {
  CartStatus,
  Exact,
  PatientsDocument,
  UpsertCartInput,
} from "../../graphql/generated/typedDocumentNodes";
import { generateUUID } from "../../utils/uuid";
import { useChain } from "../chain";

type CartItem = NonNullable<CartQuery["cart"]>["items"][number];

const MAX_ITEM_QUANTITY = 9;

// Necessary to use a reactive var so that all components
// calling this hook have shared state. Otherwise,
// when updating the id, only the component calling the
// update will have the updated value.
const cartIdVar = makeVar<string | null>(null);
export const useCartId = () => {
  const [cartIdFromLocalStorage] = useLocalStorage("cartUUID", generateUUID(), { raw: true });
  const cartIdFromVar = useReactiveVar(cartIdVar);
  const cartId = cartIdFromVar ?? cartIdFromLocalStorage;

  useEffect(() => {
    if (cartIdFromVar === null) cartIdVar(cartIdFromLocalStorage);
  }, [cartIdFromLocalStorage, cartIdFromVar]);

  return normalizeCartId(cartId) as string;
};

const useUpdateCartId = () => {
  const [, setValue] = useLocalStorage("cartUUID", generateUUID(), { raw: true });
  const cartId = useCartId();

  const update = useCallback(
    (newCartId: string) => {
      if (cartId !== newCartId) {
        cartIdVar(newCartId);
        setValue(newCartId);
      }
    },
    [cartId, setValue]
  );

  return update;
};

export const useRefreshCartId = () => {
  const updateCartId = useUpdateCartId();

  const refresh = useCallback(() => {
    const newCartId = normalizeCartId(generateUUID())!;

    return updateCartId(newCartId);
  }, [updateCartId]);

  return refresh;
};

export const useCart = () => {
  const chain = useChain();

  let cart: NonNullable<CartQuery["cart"]> = {
    __typename: "Cart",
    id: "",
    items: [],
    subtotal: "0",
    total: "0",
    status: CartStatus.Open,
    discounts: "0",
    fees: "0",
  };
  let loading = true;

  const cartId = useCartId();

  const { data, loading: dataLoading } = useCartQuery({
    variables: { id: cartId, shopId: chain.id },
    fetchPolicy: "cache-first",
    skip: !cartId,
    onCompleted: useSyncCartId(),
  });
  cart = data?.cart ?? cart;
  loading = dataLoading;

  return { cart, loading };
};

type ProductToIncrement =
  IncrementCartItemMutation["incrementCartItem"]["items"][number]["product"];
export const useIncrementCartItem = () => {
  const cartId = useCartId();
  const { data: cartData } = useCartQuery({
    skip: !cartId,
    variables: { id: cartId! },
    onCompleted: useSyncCartId(),
  });
  const cart = cartData?.cart;
  const [incrementCartItem, { data, ...rest }] = useIncrementCartItemMutation();

  // Cart needs to be referenced through ref otherwise a weird bug
  // happens where the cart data of the previous optimisticResponse
  // instead of the current cart is used by the increment function
  // to calculate the next optimisticResponse.
  const cartRef = useRef<typeof cart>(cart);
  useEffect(() => {
    cartRef.current = cart;
  }, [cart]);

  const increment = useCallback(
    (product: ProductToIncrement, quantity: number = 1) => {
      const cart = cartRef.current;
      const { items, total, subtotal } = cart ?? {};

      if (!cartId || !items || !cart) return;

      return incrementCartItem({
        variables: {
          input: {
            cartId,
            item: {
              productId: product.id,
              quantity,
            },
          },
        },
        optimisticResponse: () => {
          const item = items.find(item => item.product.id === product.id);

          const incrementable = item?.incrementable ?? true;
          const currentQuantity = item?.quantity ?? 1;

          const newQuantity =
            !incrementable && quantity > 0
              ? currentQuantity
              : Math.max(currentQuantity + quantity, 0);
          const delta = newQuantity - currentQuantity;

          const updatedItems = items
            .map(item => {
              if (item.product.id === product.id) {
                return {
                  ...item,
                  quantity: newQuantity,
                  total: b`${item.total} + ${product.price} * ${delta}`.toFixed(2),
                };
              } else {
                return item;
              }
            })
            .filter(item => item.quantity > 0);

          return {
            __typename: "Mutation",
            incrementCartItem: {
              ...cart,
              __typename: "Cart",
              id: cartId,
              items: updatedItems,
              subtotal: b`${subtotal} + ${product.price} * ${delta}`.toFixed(2),
              total: b`(${total} + ${product.price} * ${delta})`.toFixed(2),
            },
          };
        },
      });
    },
    [cartId, incrementCartItem, cartRef]
  );

  return [increment, { data: data?.incrementCartItem, ...rest }] as const;
};

export const useDecrementCartItem = () => {
  const [increment, metadata] = useIncrementCartItem();

  const decrement = useCallback(
    (product: ProductToIncrement, quantity: number = 1) => increment(product, -quantity),
    [increment]
  );

  return [decrement, metadata] as const;
};

export const useClearProductFromCart = () => {
  const cartId = useCartId();
  const [incrementCartItem, metadata] = useIncrementCartItemMutation();

  const clearProductFromCart = (product: Pick<ProductToIncrement, "__typename" | "id">) => {
    return incrementCartItem({
      variables: { input: { cartId, item: { productId: product.id, quantity: -99999 } } },
    });
  };

  return [clearProductFromCart, metadata] as const;
};

export const isCartItemIncrementable = ({ quantity }: CartItem) => quantity < MAX_ITEM_QUANTITY;

const normalizeCartId = (id: string | null | undefined) => id?.replace(/-/g, "");

const useSyncCartId = () => {
  const updateCartId = useUpdateCartId();
  return useCallback(
    (data?: any) => {
      const cart = data.cart ?? data.upsertCart;
      if (cart == null || cart.status !== CartStatus.Open) {
        updateCartId(normalizeCartId(generateUUID()) as string);
      } else if (cart) {
        updateCartId(cart.id);
      }
    },
    [updateCartId]
  );
};

export const useFinishCart = () => {
  const cartId = useCartId();
  const [checkoutCart, metadata] = useCheckoutCartMutation();

  const checkout = useCallback(
    () =>
      checkoutCart({
        variables: {
          input: { cartId: cartId! },
        },
      }),
    [cartId, checkoutCart]
  );

  return [checkout, metadata] as const;
};

type UpsertCartInputWithoutId = Exact<{
  input: Omit<UpsertCartInput, "cartId">;
}>;
export const useUpsertCart = () => {
  const cartId = useCartId();
  const [upsert, rest] = useUpsertCartMutation({
    onCompleted: useSyncCartId(),
  });

  const upsertCart = useCallback(
    (props: MutationHookOptions<UpsertCartMutation, UpsertCartInputWithoutId>) => {
      return upsert({
        ...props,
        variables: {
          ...(props.variables ?? {}),
          input: {
            ...(props.variables?.input ?? {}),
            cartId: cartId!,
          },
        },
      });
    },
    [cartId, upsert]
  );

  return [upsertCart, rest] as const;
};

export const useUpsertPatient = () => {
  const [upsert, rest] = useUpsertPatientMutation();

  const upsertPatient = useCallback(
    async (patient: UpsertPatientInput) => {
      const upsertResult = await upsert({
        variables: {
          input: pick(patient, ["birthDate", "id", "motherName", "name", "cpf", "sex"]),
        },
        optimisticResponse: patient.id
          ? {
              __typename: "Mutation",
              upsertPatient: { __typename: "Patient", id: patient.id, ...patient },
            }
          : undefined,
        awaitRefetchQueries: true,
        refetchQueries: [{ query: PatientsDocument }],
      });

      return upsertResult;
    },
    [upsert]
  );

  return [upsertPatient, rest] as const;
};

const sortCartItems = <T extends CartFragment>(cart: T): T => ({
  ...cart,
  items: sortBy(cart.items, item => item.product.name.toUpperCase()),
});

export const useFullCart = ({
  useQueryOptions,
}: { useQueryOptions?: Partial<Parameters<typeof useFullCartQuery>[0]> } = {}) => {
  const cartId = useCartId();
  const { data, ...rest } = useFullCartQuery({
    variables: {
      id: cartId!,
    },
    skip: !cartId,
    onCompleted: useSyncCartId(),
    ...useQueryOptions,
  });

  const cart = useMemo(
    () => (data?.cart ? sortCartItems(data.cart) : undefined),
    [data]
  ) as FullCartQuery["cart"];

  return { data: cart, ...rest };
};

export const useAssociateCartItemToPatient = () => {
  const [associate, rest] = useAssociateCartItemToPatientMutation();

  const associatePatientWithItem = useCallback(
    async ({ patientItemId, patientId }: { patientItemId: string; patientId: string }) => {
      const result = await associate({
        variables: { input: { cartItemId: patientItemId, patientId } },
      });

      return result;
    },
    [associate]
  );

  return [associatePatientWithItem, rest] as const;
};

type FeeRange = {
  min: number;
  max: number;
} | null;
export const useServiceFee = () => {
  const chain = useChain();

  const cartId = useCartId();
  const { data } = useCartFieldsForFeeQuery({ variables: { id: cartId } });
  const { address, clinic, service } = data?.cart ?? {};

  const geoCoordinates = address?.geoLocation;
  const skipChainQuery = Boolean(!service || clinic);

  const chainServicePriceRangeQuery = useChainServicePriceRangeQuery({
    skip: skipChainQuery,
    fetchPolicy: "cache-first",
    variables: {
      chain: chain.handle,
      service: service!,
      geoCoordinates: geoCoordinates! && pick(geoCoordinates, ["lat", "lng"]),
    },
  });

  const chainAttendsLocationQuery = useChainAttendsLocationQuery({
    skip: skipChainQuery || !geoCoordinates,
    fetchPolicy: "cache-first",
    variables: {
      chain: chain.handle,
      geoCoordinates: geoCoordinates! && pick(geoCoordinates, ["lat", "lng"]),
    },
  });

  const clinicAttendsLocationQuery = useClinicAttendsLocationQuery({
    skip: !service || !clinic || !geoCoordinates,
    fetchPolicy: "cache-first",
    variables: {
      clinicId: clinic?.id!,
      service: service!,
      geoCoordinates: geoCoordinates! && pick(geoCoordinates, ["lat", "lng"]),
    },
  });

  const loading =
    chainServicePriceRangeQuery.loading ||
    chainAttendsLocationQuery.loading ||
    clinicAttendsLocationQuery.loading;

  const servicePriceRange = chainServicePriceRangeQuery.data?.chain?.servicePriceRange;
  const servicePrice = clinicAttendsLocationQuery.data?.clinic?.servicePrice;

  const fee = {
    min: parseFloat(servicePriceRange?.min ?? servicePrice ?? "0"),
    max: parseFloat(servicePriceRange?.max ?? servicePrice ?? "0"),
  };

  return {
    loading,
    fee:
      !loading &&
      (fee.min > 0 || servicePrice) &&
      (!chainAttendsLocationQuery.data || chainAttendsLocationQuery.data?.chain?.attendsLocation)
        ? fee
        : null,
  } as { loading: boolean; fee: FeeRange };
};

export const getTotalWithoutFee = ({
  total,
  fee,
}: {
  total: number | string;
  fee: FeeRange | undefined;
}) => (typeof total === "string" ? parseInt(total, 10) : total) - (fee?.min || 0);

export const useCartPromocode = () => {
  const cartId = useCartId();

  const { data, ...rest } = useCartPromoCodeQuery({ variables: { id: cartId } });
  return { promoCode: data?.cart?.promoCode, ...rest };
};
