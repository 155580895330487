import { StackProps, Text, VStack } from "@chakra-ui/react";

import { MinimumProductFragment } from "../../graphql/generated/apolloHooks";
import { ProductDescription } from "./Description";

export const ProductNameDescription = ({
  product,
  rootProps,
}: {
  product: MinimumProductFragment;
  rootProps?: StackProps;
}) => {
  return (
    <VStack spacing={2} alignItems="flex-start" flexGrow={1} {...rootProps}>
      <Text color="text.900">{product.name}</Text>

      <ProductDescription product={product}>
        {description => (
          <Text color="text.700" fontSize="sm">
            {description}
          </Text>
        )}
      </ProductDescription>
    </VStack>
  );
};
