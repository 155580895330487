import React, { ComponentProps, Fragment, forwardRef, useEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  SimpleGrid,
  Slide,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

import b from "bigjs-literal";
import Link from "next/link";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { useIsomorphicLayoutEffect } from "react-use";

import { AmountCounter, ExpandableLinkButton, PageLimit, Price, ProductNameDescription } from "..";
import { AppointmentService, CartQuery } from "../../graphql/generated/apolloHooks";
import { isCartItemIncrementable, useIncrementCartItem, useServiceFee } from "../../models/cart";
import { useCart } from "../../models/cart";
import { formatMoney } from "../../models/money";
import { productPriceAVista } from "../../models/product";
import { ROUTES } from "../../models/router";
import { useCartDrawerProps } from "../../models/router";

const Item = ({
  item,
  productProps,
  readOnly = false,
}: {
  item: NonNullable<CartQuery["cart"]>["items"][number];
  productProps?: ComponentProps<typeof ProductNameDescription>["rootProps"];
  readOnly: boolean;
}) => {
  const [incrementCartItem] = useIncrementCartItem();

  return (
    <>
      <Stack align="center" direction="row" spacing={4}>
        <AmountCounter
          amount={item.quantity}
          readOnly={readOnly}
          isIncreaseDisabled={!isCartItemIncrementable(item)}
          onIncreaseAmount={() => incrementCartItem(item.product, 1)}
          onReduceAmount={() => incrementCartItem(item.product, -1)}
          rootProps={{ pr: readOnly ? 3 : 0 }}
        />
        <Text color="text.900" isTruncated>
          {formatMoney(productPriceAVista(item.product))}
        </Text>
      </Stack>
      <ProductNameDescription product={item.product} rootProps={productProps} />
    </>
  );
};

const CartDrawerContent = forwardRef<
  HTMLDivElement,
  { readOnly: boolean; updateHeight: () => void }
>(({ updateHeight, readOnly = false }, ref) => {
  const [shouldDisplayAllItems, setShouldDisplayAllItems] = useState(false);

  useEffect(() => {
    updateHeight();
  }, [shouldDisplayAllItems, updateHeight]);

  const { cart } = useCart();
  const { items: cartItems, subtotal, total } = cart ?? {};
  const { fee } = useServiceFee();

  const showFee = Boolean(cart?.service === AppointmentService.HomeVaccination && readOnly && fee);

  return (
    <Box ref={ref} backgroundColor="background.primary" boxShadow="baseInverted" paddingY={6}>
      <PageLimit>
        {cartItems.length > 0 && (
          <SimpleGrid
            columns={3}
            spacingX={4}
            spacingY={3}
            gridTemplateColumns="min-content 1fr min-content"
            alignItems="center"
            w="full"
          >
            {shouldDisplayAllItems && cartItems.length > 1 && (
              <Box gridColumn="span 3">
                <ExpandableLinkButton
                  size="sm"
                  isOpen={shouldDisplayAllItems}
                  onClick={() => setShouldDisplayAllItems(false)}
                >
                  ver menos
                </ExpandableLinkButton>
              </Box>
            )}

            <Item readOnly={readOnly} item={cartItems[0]} />

            <Stack
              direction={readOnly ? "column" : "row"}
              spacing={readOnly ? 1 : 4}
              align={readOnly ? "flex-end" : "center"}
            >
              {showFee && (
                <Text fontSize="sm" isTruncated>
                  Taxa domiciliar{" "}
                  {(fee?.min !== fee?.max ? "a partir de " : "de ") + formatMoney(fee?.min ?? 0)}
                </Text>
              )}

              <Price
                price={parseFloat(subtotal)}
                discountPrice={readOnly ? parseFloat(total) : parseFloat(subtotal)}
                horizontal={showFee}
                rootProps={{ spacing: 1 }}
              />

              {!readOnly && (
                <Link href={ROUTES.CART.INDEX}>
                  <Button size="lg">Finalizar compra</Button>
                </Link>
              )}
            </Stack>

            {cartItems.length > 1 &&
              (shouldDisplayAllItems ? (
                cartItems
                  .slice(1)
                  .map((item, index) => (
                    <Item
                      readOnly={readOnly}
                      key={index}
                      item={item}
                      productProps={{ gridColumnStart: "span 2" }}
                    />
                  ))
              ) : (
                <>
                  <Text color="text.900" fontSize="sm" justifySelf="flex-end" isTruncated>
                    {formatMoney(
                      cartItems
                        .slice(1)
                        .reduce((sum, item) => b`${sum} + ${item.total}`, b`0`)
                        .toFixed(2)
                    )}
                  </Text>
                  <HStack spacing={1} gridColumnStart="span 2">
                    <Text color="text.900" fontSize="sm">
                      Outros {cartItems.length - 1} produtos
                    </Text>
                    <ExpandableLinkButton
                      size="sm"
                      isOpen={shouldDisplayAllItems}
                      onClick={() => setShouldDisplayAllItems(true)}
                    >
                      ver tudo
                    </ExpandableLinkButton>
                  </HStack>
                </>
              ))}
          </SimpleGrid>
        )}
      </PageLimit>
    </Box>
  );
});

type CartProps = { visible: boolean; readOnly: boolean };

export const useIsCartOpen = () => {
  const { cart } = useCart();
  const { visible } = useCartDrawerProps();
  return cart && cart.items.length > 0 && visible;
};

export const Cart = ({ visible = true, ...props }: CartProps) => {
  const { cart } = useCart();
  const cartItems = cart.items;
  const paddingBoxRef = useRef<HTMLDivElement | null>(null);
  const realBoxRef = useRef<HTMLDivElement | null>(null);

  const updateHeight = () => {
    if (paddingBoxRef.current && realBoxRef.current)
      paddingBoxRef.current.style.height = `${realBoxRef.current.clientHeight}px`;
  };
  useEffect(() => {
    const callback = () => updateHeight();
    window.addEventListener("resize", callback);
    return () => window.removeEventListener("resize", callback);
  }, []);

  useIsomorphicLayoutEffect(() => {
    updateHeight();
  }, [realBoxRef.current?.clientHeight]);

  return (
    <>
      <Collapse in={cartItems.length > 0 && visible}>
        <Box ref={paddingBoxRef} height={24} />
      </Collapse>
      <Slide direction="bottom" in={cartItems.length > 0 && visible}>
        <CartDrawerContent ref={realBoxRef} updateHeight={updateHeight} {...props} />
      </Slide>
    </>
  );
};

const MobileCart = ({ visible = true, readOnly = false }: CartProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { cart } = useCart();
  const { items: cartItems, subtotal, total } = cart;
  const { fee } = useServiceFee();
  const [incrementCartItem] = useIncrementCartItem();

  return (
    <>
      <Slide
        direction="bottom"
        in={!isOpen && cartItems.length > 0 && visible}
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <Button size="lg" borderRadius="0" onClick={onOpen}>
          <Flex alignItems="stretch" width="full">
            <Box w="90px">
              <Box as={HiOutlineShoppingCart} w="24px" h="24px" />
            </Box>

            <Box flex="1">Ver carrinho</Box>

            <Box w="90px" textAlign="right">
              {formatMoney(readOnly ? total : subtotal)}
            </Box>
          </Flex>
        </Button>
      </Slide>

      <Drawer isOpen={isOpen && visible} onClose={onClose} size="full" placement="bottom">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton right={4} top={4} />

          <DrawerHeader
            textAlign="center"
            borderBottom="1px solid"
            borderColor={useColorModeValue("gray.100", "whiteAlpha.200")}
          >
            Carrinho
          </DrawerHeader>

          <DrawerBody display="flex" paddingX={4}>
            {cartItems.length === 0 && <Text color="text.900">Seu carrinho está vazio</Text>}

            {cartItems.length > 0 && (
              <Flex direction="column" flex="1">
                <VStack alignItems="stretch" flex="1">
                  {cartItems.map((item, i) => (
                    <Fragment key={item.product.id}>
                      {i > 0 && <Divider />}

                      <Flex alignItems="center">
                        <VStack flex="1" alignItems="flex-start">
                          <Text color="text.900">{item.product.name}</Text>
                          <Text color="text.900">
                            <Price
                              priceProps={{ fontSize: "md" }}
                              price={productPriceAVista(item.product)}
                            />
                          </Text>
                        </VStack>
                        <Box w="82px">
                          <AmountCounter
                            amount={item.quantity}
                            readOnly={readOnly}
                            rootProps={{ justifyContent: "flex-end", pr: readOnly ? 2 : 0 }}
                            isIncreaseDisabled={!isCartItemIncrementable(item)}
                            onIncreaseAmount={() => incrementCartItem(item.product, 1)}
                            onReduceAmount={() => incrementCartItem(item.product, -1)}
                          />
                        </Box>
                      </Flex>
                    </Fragment>
                  ))}
                </VStack>
                <VStack alignItems="stretch">
                  {cart.service === AppointmentService.HomeVaccination && readOnly && fee && (
                    <Flex alignItems="baseline">
                      <Text color="text.900" flex="1">
                        Taxa domiciliar
                      </Text>
                      <Text color="text.900" display="inline">
                        {fee.min !== fee.max && "a partir de "}
                        {formatMoney(fee.min)}
                      </Text>
                    </Flex>
                  )}

                  <Flex alignItems="baseline">
                    <Text color="text.900" flex="1">
                      Total
                    </Text>
                    <Text color="text.900">
                      <Price
                        price={parseFloat(subtotal)}
                        discountPrice={readOnly ? parseFloat(total) : parseFloat(subtotal)}
                        horizontal
                      />
                    </Text>
                  </Flex>

                  {!readOnly && (
                    <>
                      <Divider />
                      <Link href={ROUTES.CART.INDEX}>
                        <Button size="lg" onClick={onClose} disabled={cartItems.length === 0}>
                          Finalizar compra
                        </Button>
                      </Link>
                    </>
                  )}
                </VStack>
              </Flex>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export const CartDrawer = () => {
  const props = useCartDrawerProps();
  const Drawer = useBreakpointValue({ base: MobileCart, md: Cart });
  return Drawer ? <Drawer {...props} /> : null;
};
