import { ComponentProps } from "react";

import { Layout } from ".";
import { BackButton } from "..";
import { apolloServerClient } from "../../graphql";
import { ChainAndClinicsQuery } from "../../graphql/generated/apolloHooks";
import { ChainAndClinicsDocument } from "../../graphql/generated/typedDocumentNodes";
import { ChainProvider } from "../../models/chain";

export type ApplicationContextProps = {
  layout: { chain: NonNullable<ChainAndClinicsQuery["chain"]> } & ComponentProps<typeof BackButton>;
};

export const ApplicationContextLayout: Layout<ApplicationContextProps> = ({
  children,
  layout: { chain },
}) => {
  return <ChainProvider value={chain}>{children}</ChainProvider>;
};

ApplicationContextLayout.getLayout = (page, props) => (
  <ApplicationContextLayout {...props}>{page}</ApplicationContextLayout>
);

export const getApplicationContextProps = async (
  hostname: string | undefined
): Promise<
  | {
      props: ApplicationContextProps;
    }
  | {
      notFound: true;
    }
> => {
  if (!hostname) return { notFound: true };

  const {
    data: { chain },
  } = await apolloServerClient.query({
    query: ChainAndClinicsDocument,
    variables: {
      chain: hostname,
    },
  });

  if (!chain) return { notFound: true };
  if (!chain.enabled) return { notFound: true };

  return {
    props: { layout: { chain } } as ApplicationContextProps,
  };
};
