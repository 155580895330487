import { ReactNode, createContext, useContext } from "react";

import { ChainAndClinicsQuery } from "../../graphql/generated/typedDocumentNodes";

const ChainContext = createContext({} as NonNullable<ChainAndClinicsQuery["chain"]>);

export const ChainProvider = ({
  value,
  children,
}: {
  value: NonNullable<ChainAndClinicsQuery["chain"]>;
  children: ReactNode;
}) => <ChainContext.Provider value={value}>{children}</ChainContext.Provider>;

export const useChain = () => useContext(ChainContext);

export const useAcceptCreditCard = () => {
  const chain = useContext(ChainContext);
  return chain.acceptsCreditCard;
};

export const useAcceptPix = () => {
  const chain = useContext(ChainContext);
  return chain.acceptsPix;
};
