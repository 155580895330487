import { useMemo } from "react";

import { Box, Link, LinkProps, useBreakpointValue } from "@chakra-ui/react";

import { useChain } from "../../models/chain";
import { buildWhatsappWebLink } from "../../models/whatsapp";
import { useIsCartOpen } from "../Cart";
import { WhatsappIcon } from "./WhatsappIcon";

export const FloatingWhatsappButton = () => {
  const isCartOpen = useIsCartOpen();
  const { whatsappNumber, name } = useChain();

  const defaultProps = { display: "none" } as LinkProps;

  const defaultText = useMemo(
    () =>
      `Olá, tudo bem? Estou na ${
        name.toLowerCase().includes("loja") ? "" : "Loja Online "
      }${name} e gostaria de esclarecer algumas dúvidas.`,
    [name]
  );

  const cartOpenDimensions =
    useBreakpointValue<LinkProps>({
      base: { right: "15px", bottom: "70px", zIndex: "overlay" },
      md: { right: "30px", bottom: "130px", zIndex: "popover" },
    }) || defaultProps;

  const cartClosedDimensions =
    useBreakpointValue<LinkProps>({
      base: { right: "15px", bottom: "20px", zIndex: "overlay" },
      md: { right: "30px", bottom: "30px", zIndex: "popover" },
    }) || defaultProps;

  return whatsappNumber ? (
    <Link
      position="fixed"
      width="45px"
      height="45px"
      transition="bottom 200ms"
      borderRadius="full"
      target="_blank"
      href={buildWhatsappWebLink({ phoneNumber: whatsappNumber, text: defaultText })}
      {...(isCartOpen ? cartOpenDimensions : cartClosedDimensions)}
    >
      <Box
        width="100%"
        height="100%"
        backgroundColor="#32a852"
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="full"
      >
        <WhatsappIcon color="white" boxSize="6" />
      </Box>
    </Link>
  ) : null;
};
