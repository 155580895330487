import { ReactNode } from "react";

import { prop } from "remeda";

import { MinimumProductFragment } from "../../graphql/generated/apolloHooks";

type ProductDescriptionProps = {
  children: (description: string) => ReactNode;
  product: MinimumProductFragment;
};

export const ProductDescription = ({ children, product }: ProductDescriptionProps) => {
  switch (product.__typename) {
    case "VaccineProduct": {
      const description = product.manufacturers.map(prop("name")).join(", ");
      return description ? <>{children(description)}</> : null;
    }

    case "BundleProduct": {
      const description = product.shortDescription;
      return description ? <>{children(description)}</> : null;
    }
  }
};
