import { Image, ImageProps } from "@chakra-ui/image";

export const QRCode = ({
  url,
  size = 200,
  ...props
}: { url: string; size: number } & ImageProps) => {
  const query = new URLSearchParams({
    chs: `${size}x${size}`,
    cht: "qr",
    chl: url,
    choe: "UTF-8",
    chld: "M|4",
  }).toString();

  const imgURL = `https://quickchart.io/chart?${query}`;
  return <Image src={imgURL} alt={url} {...props} />;
};
