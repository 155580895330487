export const ROUTES = {
  API: {
    LOGO: "/api/logo",
    BANNER: "/api/banner",
  },
  DETAILS: (id: string, slug?: string) => `/produtos/${id}${slug ? `/${slug}` : ""}`,
  INDEX: "/",
  CART: {
    INDEX: "/pedido",
    SELECT_DATE: "/pedido/horario",
    REGISTER: "/pedido/registrar",
    LOGIN: "/pedido/login",
    FORGOT_PASSWORD: "/pedido/esqueci_minha_senha",
    RESET_PASSWORD: "/pedido/redefinir_senha",
    SELECT_VACCINATED: "/pedido/pacientes",
    SELECT_CLINIC: "/pedido/unidades",
    REVIEW: "/pedido/resumo",
    PAYMENT_METHOD: "/pedido/pagamento",
    APPOINTMENT: {
      INDEX: (appointmentId: string) => `/pedidos/${appointmentId}`,
    },
  },
  APPOINTMENTS: "/pedidos",
  PROFILE: "/perfil",
  SITEMAP: "/sitemap.xml",
  ROBOTS: "/robots.txt",
} as const;
