import { format, formatDistanceStrict, formatISO, isEqual, startOfToday } from "date-fns";
import { ptBR } from "date-fns/locale";

export const formatDate = (
  date: Date | number,
  formatStr: string = "dd/MM/yyyy",
  options: Parameters<typeof format>[2] = {}
) => format(date, formatStr, { locale: ptBR, ...options });

export const formatISODate = (date: Date | number) => formatISO(date, { representation: "date" });

export const formatDistance: typeof formatDistanceStrict = (date, baseDate, options = {}) =>
  formatDistanceStrict(date, baseDate, { locale: ptBR, roundingMethod: "floor", ...options });

export const formatTime = (time: Date) => formatDate(time, "HH:mm");

export const formatTimeRange = (timeRange: string, asRange: boolean = true) => {
  if (timeRange?.includes("--")) {
    const [start, end] = timeRange.split("--");
    return asRange ? `Entre ${start} e ${end}` : `${start}`;
  } else return timeRange;
};

export const formatAge = (birthDate: Date) => {
  const today = startOfToday();
  // Handle when the dates are the same to show '0 dias' instead of '0 segundos'
  return isEqual(today, birthDate) ? "0 dias" : formatDistance(birthDate, today);
};
