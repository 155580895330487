import React from "react";

import { Layout } from ".";
import { FloatingWhatsappButton } from "../Whatsapp";

export const WhatsappLayout: Layout = ({ children }) => (
  <>
    {children}
    <FloatingWhatsappButton />
  </>
);

WhatsappLayout.getLayout = (page, props) => <WhatsappLayout {...props}>{page}</WhatsappLayout>;
