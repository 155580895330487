export const buildWhatsappWebLink = ({
  phoneNumber,
  text = "",
}: {
  phoneNumber: string;
  text?: string;
}) => {
  const query = new URLSearchParams({
    phone: ("55" + phoneNumber).replace(/\D/g, ""),
    lang: "pt-br",
    text,
  }).toString();

  return `https://api.whatsapp.com/send?${query}`;
};
