import { forwardRef } from "react";

import ReactMaskedInput from "react-text-mask";

import { MaskedInput, MaskedInputPropsOptionalMask } from ".";

const dayMonthYearMask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];

export const DateMaskInput = forwardRef<ReactMaskedInput, MaskedInputPropsOptionalMask>(
  ({ mask, ...props }, ref) => (
    <MaskedInput mask={mask ?? dayMonthYearMask} ref={ref} showMask={false} {...props} />
  )
);
