import { ChangeEvent, Fragment, useEffect, useMemo, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  StackProps,
  Text,
  VStack,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";

import { useReactiveVar } from "@apollo/client";
import { makeVar } from "@apollo/client";
import debounce from "debounce";
import { Controller, useFormContext } from "react-hook-form";
import { FaMapMarkerAlt } from "react-icons/fa";
import * as yup from "yup";

import { LinkButton, ZipCodeInput } from "..";
import { useGooglePlacesAutocomplete } from "../../lib/google-maps";
import {
  coordinates,
  placeAddress,
  placeCoordinates,
  predictionMainText,
  predictionSecondaryText,
} from "../../lib/google-maps/projections";

type GoogleAddress = {
  country: string;
  countryAbbreviation: string;
  state: string;
  stateAbbreviation: string;
  city: string;
  district?: string;
  street: string;
  number?: string;
  zipCode?: string;
  coordinates: { lat: number; lng: number };
};
export const addressSearchVar = makeVar(null as null | GoogleAddress);

export const getAddressFormSchema = (googleAddress: ReturnType<typeof addressSearchVar>) =>
  yup
    .object({
      zipCode: (() => {
        let schema = yup
          .string()
          .transform(value => (value ? value.replace(/\D/g, "") : value))
          .matches(/^\d\d\d\d\d\d\d\d$/, {
            message: "CEP deve possuir 8 dígitos",
            excludeEmptyString: true,
          })
          .label("CEP");

        if (!googleAddress?.zipCode) schema = schema.required();
        return schema;
      })(),
      district: (() => {
        let schema = yup.string().label("Bairro");

        if (!googleAddress?.district) schema = schema.required();
        return schema;
      })(),
      number: yup
        .string()
        .label("Número")
        .when("withoutNumber", {
          is: false,
          then: yup.string().required('Marque "Sem número" se seu endereço não conter um número'),
        }),
      withoutNumber: yup.boolean().required().label("Sem número"),
      complement: yup.string().label("Complemento"),
    })
    .required();

const AddressSearchModal = ({ isOpen, close }: { isOpen: boolean; close: () => void }) => {
  const {
    predictions,
    search,
    getDetails,
    reverseGeocode,
    registerAttributionRef,
    clearPredictions,
  } = useGooglePlacesAutocomplete();

  const debouncedSearch = useMemo(() => debounce(search, 400), [search]);

  const getLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async position => {
        const geocoded = await reverseGeocode({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        const place =
          geocoded &&
          (await getDetails({
            placeId: geocoded.place_id,
            fields: ["address_components", "geometry"],
          }));

        if (place) {
          const address = placeAddress(place);

          if (
            (["city", "state", "street", "zipCode"] as (keyof typeof address)[]).every(
              field => address[field]
            )
          ) {
            addressSearchVar({
              ...address,
              coordinates: coordinates(place.geometry!.location!),
            });
            onClose();
            return;
          }
        }

        alert(
          "Obtenção de localização automática falhou, por favor, preencha seu endereço manualmente."
        );
      });
    } else {
      alert("Obtenção de localização não é suportada.");
    }
  };

  const onSelectPrediction = async (prediction: google.maps.places.AutocompletePrediction) => {
    const place = await getDetails({
      placeId: prediction.place_id,
      fields: ["address_components", "geometry"],
    });
    const address = { ...placeAddress(place), coordinates: placeCoordinates(place) };

    if ((["city", "state", "street"] as (keyof typeof address)[]).some(field => !address[field])) {
      alert("Por favor, inclua mais informações no seu endereço.");
      return;
    }
    addressSearchVar(address);
    onClose();
  };

  const onClose = () => {
    clearPredictions();
    close();
  };

  const [input, setInput] = useState("");
  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setInput(input);
    if (input.trim().length > 3)
      debouncedSearch({
        input,
        types: ["address"],
        componentRestrictions: { country: "br" },
      });
  };

  const modalSize = useBreakpointValue({ base: "full", md: "lg" });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={modalSize}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Informe seu endereço</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex display="flex" w="full" align="center" justify="center">
            <Flex
              w="full"
              direction="column"
              align="center"
              justify="center"
              pb={predictions.length > 0 ? 2 : 4}
            >
              <Flex
                direction="column"
                w="full"
                align="flex-start"
                pb={predictions.length > 0 ? 6 : 0}
              >
                <Input placeholder="Endereço e número" flexGrow={1} onChange={onChangeInput} />

                <LinkButton leftIcon={<>⦿</>} mt={6} onClick={getLocation}>
                  Usar minha localização
                </LinkButton>
                <Box display="none" ref={registerAttributionRef}></Box>
              </Flex>

              <VStack w="full">
                {input.trim().length > 0 &&
                  predictions.map((prediction, i) => (
                    <Fragment key={i}>
                      <Flex
                        px={6}
                        py={2}
                        onClick={() => onSelectPrediction(prediction)}
                        cursor="pointer"
                        align="flex-start"
                        w="full"
                      >
                        <Box as={FaMapMarkerAlt} w={6} h={6} mx={6} my={3} ml={0} />
                        <VStack spacing={0} align="flex-start">
                          <Text fontSize="lg">{predictionMainText(prediction)}</Text>
                          <Text color="text.500">{predictionSecondaryText(prediction)}</Text>
                        </VStack>
                      </Flex>
                      {i < predictions.length - 1 && <Divider />}
                    </Fragment>
                  ))}
              </VStack>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export type AddressOfAddressForm = {
  country: string;
  state: string;
  city: string;
  district: string;
  street: string;
  number?: string;
  zipCode: string;
  complement?: string;
  coordinates: { lat: number; lng: number };
};

export const AddressFormFields = ({
  name,
  resetAddressFields,
  fieldsStackProps,
  defaultAddress,
}: {
  name?: string;
  resetAddressFields: (addressFields: {
    zipCode: string;
    district: string;
    number: string;
    complement: string;
    withoutNumber: boolean;
    googleAddress: AddressOfAddressForm | undefined;
  }) => void;
  fieldsStackProps?: StackProps;
  defaultAddress?: AddressOfAddressForm;
}) => {
  const withFieldName = (fieldName: string) => (name ? `${name}.${fieldName}` : fieldName);

  const {
    watch,
    setValue,
    register,
    control,
    formState: { errors: formErrors },
  } = useFormContext();

  const errors = name ? formErrors[name] : formErrors;
  const withoutNumber: boolean = watch(withFieldName("withoutNumber"));

  const {
    isOpen: isAddressSearchModalOpen,
    onOpen: openAddressSearchModal,
    onClose: closeAddressSearchModal,
  } = useDisclosure();

  const addressSearch = useReactiveVar(addressSearchVar);
  const googleAddress = addressSearch ?? defaultAddress ?? null;

  const onWithoutNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(withFieldName("withoutNumber"), e.currentTarget.checked);
    setValue(withFieldName("number"), "");
  };

  // Sets the default values coming from previously filled form values
  useEffect(() => {
    if (!addressSearch) {
      const { zipCode, district, number, complement } = defaultAddress ?? {};
      resetAddressFields({
        zipCode: zipCode ? `${zipCode.slice(0, 5)}-${zipCode.slice(5, 8)}` : "",
        district: district ?? "",
        number: number ?? "",
        complement: complement ?? "",
        withoutNumber: googleAddress ? !googleAddress.number : false,
        googleAddress: defaultAddress,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultAddress]);

  // Sets the default values and clears the rest of the form when a
  // new address is selected from Google Maps
  useEffect(() => {
    if (addressSearch) {
      const { zipCode, district, number } = addressSearch;
      resetAddressFields({
        zipCode: zipCode ? `${zipCode.slice(0, 5)}-${zipCode.slice(5, 8)}` : "",
        district: district ?? "",
        number: number ?? "",
        complement: "",
        // Always set as initially false, since the address might be
        // without a number but incomplete. In case it isn't incomplete
        // and doesn't contain a number, the user will have to check the
        // box
        withoutNumber: false,
        googleAddress: {
          ...addressSearch,
          city: addressSearch.city,
          district: district ?? "",
          state: addressSearch.stateAbbreviation,
          street: addressSearch.street,
          zipCode: zipCode ? `${zipCode.slice(0, 5)}-${zipCode.slice(5, 8)}` : "",
          number: addressSearch.number,
          complement: "",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressSearch]);

  return (
    <>
      <AddressSearchModal isOpen={isAddressSearchModalOpen} close={closeAddressSearchModal} />
      {googleAddress ? (
        <>
          <Flex direction="row" justify="space-between" alignItems="flex-start" w="full">
            <Text fontSize="xl" fontWeight="medium" lineHeight="tall">
              {googleAddress?.street ?? ""}
            </Text>
            <LinkButton
              onClick={e => {
                e.preventDefault();
                openAddressSearchModal();
              }}
            >
              Editar
            </LinkButton>
          </Flex>
          <Text color="text.900" marginTop={2.5}>
            {googleAddress?.district ?? ""}
            {googleAddress?.zipCode
              ? `, ${googleAddress.zipCode.slice(0, 5)}-${googleAddress.zipCode.slice(5, 8)}`
              : ""}
            <br /> {googleAddress?.city ?? ""} - {googleAddress?.state ?? ""}
          </Text>

          <VStack spacing={6} marginTop={6} align="flex-starts" w="full" {...fieldsStackProps}>
            <FormControl isInvalid={Boolean(errors?.district)} w="full">
              <Input placeholder="Bairro" w="full" {...register(withFieldName("district"))} />
              {errors?.district && <FormErrorMessage>{errors.district?.message}</FormErrorMessage>}
            </FormControl>

            <FormControl isInvalid={Boolean(errors?.zipCode)} w="full">
              <Controller
                control={control}
                name={withFieldName("zipCode")}
                render={({ field }) => <ZipCodeInput placeholder="CEP" w="full" {...field} />}
              />
              {errors?.zipCode && <FormErrorMessage>{errors.zipCode?.message}</FormErrorMessage>}
            </FormControl>

            <HStack spacing={6} align="flex-start" w="full">
              <FormControl isInvalid={Boolean(errors?.number)} w={40} isDisabled={withoutNumber}>
                <Input placeholder="Número" w="full" {...register(withFieldName("number"))} />
                {errors?.number && <FormErrorMessage>{errors.number?.message}</FormErrorMessage>}
              </FormControl>

              <FormControl isInvalid={Boolean(errors?.complement)} flexGrow={1}>
                <Input placeholder="Complemento" {...register(withFieldName("complement"))} />
                {errors?.complement && (
                  <FormErrorMessage>{errors.complement?.message}</FormErrorMessage>
                )}
              </FormControl>
            </HStack>
            <Controller
              control={control}
              name={withFieldName("withoutNumber")}
              render={({ field }) => (
                <Checkbox {...field} onChange={onWithoutNumberChange} isChecked={field.value}>
                  Sem número
                </Checkbox>
              )}
            />
          </VStack>
        </>
      ) : (
        <FormControl isInvalid={Boolean(errors?.googleAddress)}>
          <Button
            size="lg"
            variant="light"
            onClick={e => {
              e.preventDefault();
              openAddressSearchModal();
            }}
          >
            Definir endereço
          </Button>
          {errors?.googleAddress && (
            <FormErrorMessage>{errors.googleAddress.message}</FormErrorMessage>
          )}
        </FormControl>
      )}
    </>
  );
};
