import React, { ComponentProps } from "react";

import { ChevronLeftIcon } from "@chakra-ui/icons";
import {
  Box,
  Link as CkLink,
  Divider,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  Img,
  LinkProps,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

import NextLink from "next/link";
import { useRouter } from "next/router";

import {
  ApplicationContextProps,
  ClinicsDetails,
  LinkButton,
  PageLimit,
  useBackRoute,
  useGoBack,
} from "..";
import { ChainAndClinicsQuery } from "../../graphql/generated/apolloHooks";
import { useCurrentSession, useCurrentUser } from "../../models/auth";
import { ROUTES } from "../../models/router";

type HeaderProps = {
  chain: NonNullable<ChainAndClinicsQuery["chain"]>;
  showBanner?: boolean;
  showLogo?: Boolean;
} & ComponentProps<typeof Box>;

const Link = ({ href, ...props }: LinkProps & { href: string }) =>
  href.startsWith("/") ? (
    <NextLink href={href} passHref>
      <CkLink {...props} />
    </NextLink>
  ) : (
    <CkLink href={href} {...props} />
  );

export const Header = ({ chain, showBanner = false, showLogo = false, ...props }: HeaderProps) => {
  const { name, clinics } = chain;
  const redirectUrl = chain.redirectUrl ?? ROUTES.INDEX;

  const router = useRouter();

  const { logout } = useCurrentSession();
  const { user } = useCurrentUser();

  if (!showBanner && !showLogo) return null;

  return (
    <Box backgroundColor="background.primary" boxShadow="base" zIndex={100} {...props}>
      <PageLimit>
        <Stack className="ShopHeader__container" width="full" spacing={2.5}>
          {showBanner && (
            <Link
              href={redirectUrl}
              isExternal={redirectUrl !== ROUTES.INDEX}
              marginTop={4}
              marginBottom={showLogo ? 0 : 2}
              borderRadius="md"
            >
              <Image
                className="ShopHeader__banner"
                src={ROUTES.API.BANNER}
                width="full"
                borderRadius="md"
                maxHeight="300px"
                objectFit="cover"
              />
            </Link>
          )}

          {showLogo && (
            <HStack
              className="ShopHeader__profile"
              paddingTop={showBanner ? 0 : 2}
              paddingBottom={ROUTES.INDEX === router.asPath ? 0 : 3}
              align="center"
              spacing={6}
              zIndex="20"
              w="full"
            >
              <Link href={ROUTES.INDEX} borderRadius="md">
                <Box
                  bgImage={ROUTES.API.LOGO}
                  bgSize="contain"
                  bgRepeat="no-repeat"
                  bgPosition="center"
                  minW="180px"
                  minH="100px"
                  borderRadius="md"
                  alt={name}
                />
              </Link>

              <HStack alignItems="center" spacing={3} justifyContent="space-between" w="100%">
                <VStack spacing={3} align="start">
                  <Heading className="ShopHeader__title" fontSize="2xl">
                    {chain.hideShopName ? "" : name}
                  </Heading>
                </VStack>

                <HStack justifyContent="space-between" alignItems={"stretch"} spacing="4">
                  <ClinicsDetails clinics={clinics} alignItems="end" />

                  {user && (
                    <>
                      <Divider
                        as="div"
                        h="unset"
                        w="1.1px"
                        orientation="vertical"
                        bgColor="blackAlpha.400"
                      />
                      <VStack>
                        <Text fontSize="sm" textAlign={"center"}>
                          Usuário: <br />
                          {user.name}
                        </Text>
                        <NextLink href={ROUTES.PROFILE} passHref>
                          <LinkButton variant="link" as="a">
                            Editar perfil
                          </LinkButton>
                        </NextLink>
                        <LinkButton variant="link" as="a" href={ROUTES.INDEX} onClick={logout}>
                          Sair
                        </LinkButton>
                      </VStack>
                    </>
                  )}
                </HStack>
              </HStack>
            </HStack>
          )}
        </Stack>
      </PageLimit>
    </Box>
  );
};

export const MobileHeader = ({
  chain,
  previousRoute,
  ...props
}: HeaderProps & Pick<ApplicationContextProps["layout"], "previousRoute">) => {
  const goBack = useGoBack();
  const [storedPreviousRoute] = useBackRoute();
  const { logout } = useCurrentSession();
  const { user } = useCurrentUser();

  return (
    <Flex
      background="background.primary"
      height="70px"
      position="fixed"
      top="0"
      left="0"
      right="0"
      zIndex="100"
      alignItems="center"
      justifyContent="center"
      boxShadow="base"
      {...props}
    >
      {Boolean(storedPreviousRoute || previousRoute) && (
        <IconButton
          aria-label="Voltar"
          position="absolute"
          top={4}
          left={4}
          onClick={() => goBack(previousRoute)}
          icon={<ChevronLeftIcon />}
          variant="light"
          isRound
        />
      )}

      <Link href={ROUTES.INDEX} borderRadius="md">
        <Img src={ROUTES.API.LOGO} alt={chain.name} maxH="60px" borderRadius="md" />
      </Link>

      {user && (
        <VStack position="absolute" right="4" w="20" alignItems="center" spacing="1">
          <VStack spacing="0">
            <Text fontSize="xs" textAlign={"center"} noOfLines={2}>
              {user.name}
            </Text>
          </VStack>
          <NextLink href={ROUTES.PROFILE} passHref>
            <LinkButton variant="link" as="a" fontSize={"xs"}>
              Editar perfil
            </LinkButton>
          </NextLink>
          <LinkButton variant="link" as="a" href={ROUTES.INDEX} fontSize={"xs"} onClick={logout}>
            Sair
          </LinkButton>
        </VStack>
      )}
    </Flex>
  );
};
