import { useCallback, useState } from "react";

export const partition = <T>(fn: (item: T) => boolean, list: Array<T>): [Array<T>, Array<T>] => {
  const partitioned: [Array<T>, Array<T>] = [[], []];

  for (const item of list) {
    partitioned[fn(item) ? 0 : 1].push(item);
  }

  return partitioned;
};

export const move = <T>(list: Array<T>, from: number, to: number): Array<T> => {
  const result = [...list];
  const item = result.splice(from, 1);

  return from < 0 || from >= list.length || to < 0 || to >= list.length
    ? list
    : [...result.slice(0, to), ...item, ...result.slice(to, list.length)];
};

export const dropWhile = <T>(array: T[], predicate: (element: T) => boolean) => {
  let index = 0;
  for (; index < array.length; index++) {
    const element = array[index];

    if (!predicate(element)) break;
  }

  return array.slice(index);
};

export const replaceItemAtIndex = <T>(array: T[], index: number, newValue: T) => {
  const clone = [...array];
  clone[index] = newValue;
  return clone;
};

export const useArray = <T>(initialArray: T[]) => {
  const [array, setArray] = useState(initialArray);

  const update = useCallback(
    (index: number, setValue: (v: T) => T) =>
      setArray(currentArray =>
        replaceItemAtIndex(currentArray, index, setValue(currentArray[index]))
      ),
    []
  );

  return {
    value: array,
    update,
  };
};
