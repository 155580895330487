import {
  Box,
  FlexProps,
  Heading,
  Link,
  ListItem,
  Theme,
  UnorderedList,
  useTheme,
} from "@chakra-ui/react";

import ReactMarkdown, { ReactMarkdownOptions } from "react-markdown";
import { omit } from "remeda";

import { sectionizer } from "./plugins";

type Props = { rootProps?: FlexProps } & ReactMarkdownOptions;

export const Markdown = ({ rootProps, ...props }: Props) => {
  const theme = useTheme() as Theme;

  return (
    <Box
      sx={{ columnCount: { base: 1, lg: 2 }, columnGap: theme.space[6] }}
      fontSize="sm"
      lineHeight={6}
      {...rootProps}
    >
      <ReactMarkdown
        remarkPlugins={[sectionizer]}
        components={{
          ul: props => <UnorderedList {...(omit(props, ["ordered"]) as object)} />,
          li: props => <ListItem {...(omit(props, ["ordered"]) as object)} />,
          h3: props => (
            <Heading
              as="h3"
              fontSize="xl"
              fontWeight="medium"
              lineHeight="tall"
              marginBottom={3}
              {...props}
            />
          ),
          a: props => <Link {...props} target="_blank" overflowWrap="anywhere" />,
          section: ({ children }) => (
            <Box sx={{ breakInside: "avoid" }} mb={6} color="text.900">
              {children}
            </Box>
          ),
        }}
        {...props}
      />
    </Box>
  );
};
