import { Button, ButtonProps, chakra } from "@chakra-ui/react";

const Span = chakra("span");

export const LinkButton = ({
  children,
  textDecoration = "underline",
  color,
  href,
  ...props
}: ButtonProps & { href?: string }) => (
  <Button variant="link" size="sm" href={href} {...props}>
    <Span textDecoration={textDecoration} color={color}>
      {children}
    </Span>
  </Button>
);
