import { ReactNode } from "react";

import {
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertTitle,
  Alert as CkAlert,
  Text,
} from "@chakra-ui/react";

import { UseFormReturn } from "react-hook-form";

export const Alert = ({
  title,
  description,
  rootProps,
  status,
  belowDescriptionNode,
}: {
  status?: AlertProps["status"];
  title?: string;
  description?: ReactNode;
  rootProps?: AlertProps;
  belowDescriptionNode?: ReactNode;
}) => {
  return (
    <CkAlert status={status} borderRadius="base" {...rootProps}>
      <AlertIcon />
      {title && <AlertTitle>{title}</AlertTitle>}
      {description && <AlertDescription>{description}</AlertDescription>}
      {belowDescriptionNode}
    </CkAlert>
  );
};

export const FormErrorsAlert = ({
  errors,
  rootProps,
}: {
  errors: UseFormReturn["formState"]["errors"];
  rootProps?: AlertProps;
}) =>
  Boolean(Object.keys(errors).length) ? (
    <Alert
      status="error"
      rootProps={rootProps}
      description={Object.values(errors)
        .filter(({ message }) => Boolean(message))
        .map(({ message }, index) => (
          <Text key={index}>{message}</Text>
        ))}
    />
  ) : null;
