import { Icon, IconProps, useColorModeValue } from "@chakra-ui/react";

export const VisaIcon = (props: IconProps) => (
  <Icon viewBox="0 0 75 24" {...props}>
    <path
      d="M38.7532 7.64208C38.711 10.9719 41.7207 12.8301 43.9879 13.9349C46.3175 15.0685 47.0999 15.7954 47.091 16.809C47.0732 18.3605 45.2327 19.0451 43.51 19.0718C40.5048 19.1185 38.7577 18.2605 37.3684 17.6114L36.2859 22.6772C37.6796 23.3196 40.2603 23.8797 42.9366 23.9042C49.2182 23.9042 53.3282 20.8034 53.3504 15.9954C53.3749 9.89379 44.9104 9.55592 44.9682 6.82853C44.9882 6.00165 45.7773 5.11919 47.5067 4.89468C48.3624 4.78132 50.7253 4.69463 53.4038 5.92829L54.4552 1.02699C53.0148 0.502407 51.1632 5.14984e-05 48.8581 5.14984e-05C42.9454 5.14984e-05 38.7866 3.14311 38.7532 7.64208ZM64.5579 0.422386C63.4109 0.422386 62.444 1.09145 62.0127 2.11839L53.0392 23.5441H59.3165L60.5657 20.0921H68.2366L68.9612 23.5441H74.4938L69.6659 0.422386H64.5579ZM65.4359 6.66849L67.2475 15.3508H62.2861L65.4359 6.66849ZM31.1423 0.422386L26.1943 23.5441H32.1759L37.1217 0.422386H31.1423ZM22.2933 0.422386L16.0672 16.1599L13.5488 2.77857C13.2531 1.28484 12.0861 0.422386 10.7902 0.422386H0.611987L0.469727 1.09368C2.55917 1.54713 4.93313 2.27843 6.37129 3.06087C7.25153 3.53877 7.5027 3.95666 7.79167 5.09252L12.5618 23.5441H18.8835L28.575 0.422386H22.2933Z"
      fill="url(#paint0_linear_1178_730)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1178_730"
        x1="34.5017"
        y1="24.3837"
        x2="35.1936"
        y2="-0.166181"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={useColorModeValue("#222357", "#254AA5")} />
        <stop offset="1" stopColor="#254AA5" />
      </linearGradient>
    </defs>
  </Icon>
);
