import { ComponentProps } from "react";

import { AddIcon, MinusIcon } from "@chakra-ui/icons";

import { LinkButton } from ".";

type Props = ComponentProps<typeof LinkButton> & {
  isOpen?: boolean;
  reverseHorizontalAlign?: boolean;
};

export const ExpandableLinkButton = ({
  children,
  isOpen = false,
  reverseHorizontalAlign = false,
  ...props
}: Props) => (
  <LinkButton
    alignSelf={{ base: "flex-start", lg: reverseHorizontalAlign ? "flex-start" : "flex-end" }}
    leftIcon={isOpen ? <MinusIcon w="9px" h="9px" /> : <AddIcon w="9px" h="9px" />}
    {...props}
  >
    {children}
  </LinkButton>
);
