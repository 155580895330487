import { ComponentProps } from "react";

import { Box, BoxProps } from "@chakra-ui/react";

import { NextPage } from "next";

import { Layout } from "..";

export type Page<Props = {}, ILayout = unknown> = ILayout extends Layout<any>
  ? NextPage<Props & ComponentProps<ILayout>> & {
      getLayout: ILayout["getLayout"];
    }
  : NextPage<Props>;

export const PageLimit = (props: BoxProps) => (
  <Box
    display="flex"
    flex="1"
    margin="0 auto"
    width="full"
    maxWidth="container.xl"
    paddingX={{ base: 4, md: 8 }}
    {...props}
  />
);
