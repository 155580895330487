import { pipe } from "remeda";

export const replace = (string: string, replacement: string, regex: RegExp) =>
  string.replace(regex, replacement);

export const trim = (string: string) => string.trim();

export const toLower = (string: string) => string.toLowerCase();

export const removeAccents = (string: string) =>
  pipe(
    string,
    str => str.normalize("NFD"),
    str => replace(str, "", /\p{Diacritic}/gu)
  );

export const removeSpecialCharacters = (string: string) => replace(string, "", /[^a-zA-Z0-9 ]/g);
