import React, { ChangeEvent, ComponentProps, useMemo, useState } from "react";

import { ChevronLeftIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Theme,
  VStack,
} from "@chakra-ui/react";

import { useTheme } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Circle, Map } from "google-maps-react";
import { Controller, useForm } from "react-hook-form";
import { RiMapPinFill } from "react-icons/ri";
import { equals, omit } from "remeda";
import * as yup from "yup";

import { ZipCodeInput } from "..";
import { useGeocoder } from "../../lib/google-maps";
import { haversineDistance, useWhenChanged } from "../../utils";

type ViaCEPAddress = {
  street: string;
  zipCode: string;
  district: string;
  complement?: string;
  city: string;
  state: string;
};
const useViaCep = () => {
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState<ViaCEPAddress>();

  const search = async (zipCode: string) => {
    try {
      setLoading(true);
      const result = await fetch(`https://viacep.com.br/ws/${zipCode}/json/`).then(res =>
        res.json()
      );
      if (result.erro === true) throw new Error("Invalid zip code");
      const address: ViaCEPAddress = {
        street: result.logradouro,
        complement: result.complemento,
        district: result.bairro,
        city: result.localidade,
        state: result.uf,
        zipCode: result.cep,
      };
      setAddress(address);
      return address;
    } finally {
      setLoading(false);
    }
  };

  return [search, { loading, data: address }] as const;
};

type GeoLocation = { lat: number; lng: number };

type MapLocationSelectorProps = ComponentProps<typeof Box> & {
  coordinates: { lat: number; lng: number };
  onConfirm: (coordinates: { lat: number; lng: number }) => void;
  maxRadius?: number;
};
const MapLocationSelector = ({
  coordinates,
  onConfirm,
  onCancel,
  maxRadius = 500,
  ...props
}: MapLocationSelectorProps) => {
  const theme = useTheme() as Theme;

  const [map, setMap] = useState<google.maps.Map>();
  const [position, setPosition] = useState(coordinates);
  const [dragging, setDragging] = useState(false);
  const [zoom, setZoom] = useState(16);

  const outOfBounds = useMemo(
    () => haversineDistance(position, coordinates) > maxRadius,
    [position, coordinates, maxRadius]
  );

  useWhenChanged(
    coordinates,
    newCoordinates => {
      map?.setCenter(newCoordinates);
      setPosition(newCoordinates);
    },
    [map],
    equals
  );

  return (
    <Box
      position="relative"
      width="full"
      height="400px"
      {...props}
      onWheel={evt => setZoom(zoom => (evt.deltaY > 1 ? zoom - 1 : zoom + 1))}
    >
      <Map
        initialCenter={coordinates}
        google={window.google}
        zoom={zoom}
        draggable
        disableDefaultUI
        disableDoubleClickZoom
        scrollwheel={false}
        scaleControl={false}
        streetViewControl={false}
        onReady={(_props, map) => setMap(map)}
        onZoomChanged={(_props, map) => setZoom(map?.getZoom() || 16)}
        onDragstart={() => setDragging(true)}
        onDragend={(_props, map) => {
          setDragging(false);
          setPosition({ lat: map!.getCenter()!.lat(), lng: map!.getCenter()!.lng() });
        }}
      >
        <Circle
          center={coordinates}
          radius={maxRadius}
          visible={outOfBounds}
          strokeColor={theme.colors.red[500]}
          strokeOpacity={0.3}
          strokeWeight={1}
          fillColor={theme.colors.red[500]}
          fillOpacity={0.1}
        />
      </Map>

      <Box
        as={RiMapPinFill}
        position="absolute"
        left="50%"
        top="50%"
        w="44px"
        h="54px"
        color="primary.500"
        marginLeft="-22px"
        marginTop={dragging ? "-64px" : "-54px"}
        transition="margin 200ms"
      />

      <Popover isOpen={!dragging} autoFocus={false} placement="top" offset={[0, 50]}>
        <PopoverContent maxW="160px">
          <PopoverArrow />
          <PopoverHeader fontSize="16px" textAlign="center" paddingBottom="0" border="0">
            Você está aqui?
          </PopoverHeader>
          <PopoverBody fontSize="13px" color="text.500" paddingTop="0">
            Ajuste sua localização
          </PopoverBody>
        </PopoverContent>
        <PopoverTrigger>
          <Box
            position="absolute"
            left="50%"
            top="50%"
            w="10px"
            h="5px"
            bg="black"
            marginLeft="-5px"
            marginTop="-6px"
            borderRadius="100%"
            opacity={0.3}
          />
        </PopoverTrigger>
      </Popover>

      {!dragging && outOfBounds && (
        <Alert
          status="error"
          variant="solid"
          position="absolute"
          left="50%"
          top="20px"
          transform="translateX(-50%)"
          maxW="80%"
        >
          <AlertIcon />
          <AlertDescription>Local muito distante do endereço informado</AlertDescription>
        </Alert>
      )}

      {!dragging && !outOfBounds && (
        <Button
          size="lg"
          position="absolute"
          left="50%"
          bottom="30px"
          transform="translateX(-50%)"
          disabled={outOfBounds}
          onClick={() => onConfirm(position)}
        >
          Confirmar localização
        </Button>
      )}

      <Text
        display="flex"
        alignItems="center"
        as="button"
        onClick={onCancel}
        position="absolute"
        left="10px"
        top="10px"
        variant="roboto"
        fontSize="xl"
        color="primary.500"
      >
        <ChevronLeftIcon w={12} h={12} />
      </Text>
    </Box>
  );
};

const addressSchema = yup
  .object({
    street: yup.string().label("Logradouro").required(),
    zipCode: yup
      .string()
      .required()
      .transform(value => (value ? value.replace(/\D/g, "") : value))
      .matches(/^\d\d\d\d\d\d\d\d$/, {
        message: "CEP deve possuir 8 dígitos",
        excludeEmptyString: true,
      })
      .label("CEP"),
    district: yup.string().label("Bairro").required(),
    number: yup
      .string()
      .label("Número")
      .when("withoutNumber", {
        is: false,
        then: yup.string().required('Marque "Sem número" se seu endereço não conter um número'),
      }),
    withoutNumber: yup.boolean().required().label("Sem número"),
    complement: yup.string().label("Complemento"),
    city: yup.string().label("Cidade").required(),
    state: yup.string().label("Estado").required(),
  })
  .required();

type Address = {
  street: string;
  zipCode: string;
  district: string;
  number?: string;
  withoutNumber: boolean;
  complement?: string;
  city: string;
  state: string;
};

type InputAddress = Address & {
  coordinates?: GeoLocation;
};

type AddressSearchProps = ComponentProps<typeof Box> & {
  address?: InputAddress;
  requiresGeoCoordinates?: boolean;
  onAddress: (address: Address, location?: GeoLocation) => void;
};
export const AddressSearch = ({
  address,
  requiresGeoCoordinates = false,
  onAddress,
  ...props
}: AddressSearchProps) => {
  const form = useForm({
    defaultValues: {
      street: address?.street ?? "",
      zipCode: address?.zipCode ?? "",
      district: address?.district ?? "",
      number: address?.number ?? "",
      complement: address?.complement ?? "",
      city: address?.city ?? "",
      state: address?.state ?? "",
      withoutNumber: address?.withoutNumber ?? false,
    },
    resolver: yupResolver(addressSchema),
  });

  const { control, formState, getValues, handleSubmit, register, setValue, watch } = form;
  const { errors } = formState;
  const [coordinates, setCoordinates] = useState<GeoLocation | undefined>(address?.coordinates);
  const [tab, setTab] = useState(0);

  const withoutNumber = watch("withoutNumber");

  const onWithoutNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue("withoutNumber", e.currentTarget.checked);
    setValue("number", "");
  };

  const [searchZipCode, { loading: searching }] = useViaCep();
  const [geocode, { loading: geocoding }] = useGeocoder();

  const onZipCodeChange = async (evt: ChangeEvent<HTMLInputElement>) => {
    setValue("zipCode", evt.target.value);
    const zipCode = evt.target.value;
    if (zipCode.replace(/\D/g, "").length === 8) {
      const address = await searchZipCode(zipCode).catch(() => null);
      if (address) {
        setValue("street", address.street);
        setValue("complement", address.complement ?? "");
        setValue("district", address.district);
        setValue("city", address.city);
        setValue("state", address.state);
        setValue("zipCode", address.zipCode);
      }
    }
  };

  const onSubmit = async (values: any) => {
    const queryAddress = ({ street, number, city, state }: Address) =>
      [street, number, city, state].filter(Boolean).join(", ");

    if (address && address.coordinates && queryAddress(address) === queryAddress(values)) {
      return onAddress(omit(address, ["coordinates"]), address.coordinates);
    }

    if (!requiresGeoCoordinates) {
      return onAddress(values);
    }

    const geolocation = await geocode(queryAddress(values)).catch(() => null);

    if (geolocation && geolocation.results.length > 0) {
      const { location } = geolocation.results[0].geometry;
      setCoordinates({ lat: location.lat(), lng: location.lng() });
      setTab(1);
    }
  };

  const onConfirmLocation = (location: GeoLocation) => {
    setCoordinates(location);
    onAddress(getValues(), location);
    setTab(0);
  };

  const onCancelLocation = () => {
    setTab(0);
  };

  return (
    <Tabs index={tab} flex="1" {...(props as any)}>
      <TabPanels>
        <TabPanel as="form" onSubmit={handleSubmit(onSubmit)} flex="1" padding="0">
          <VStack spacing={6} align="flex-starts" w="full" marginBottom={5}>
            <FormControl isInvalid={Boolean(errors?.zipCode)} w="full">
              <Controller
                control={control}
                name="zipCode"
                render={({ field }) => (
                  <ZipCodeInput {...field} onChange={onZipCodeChange} placeholder="CEP" w="full" />
                )}
              />
              {errors?.zipCode && <FormErrorMessage>{errors.zipCode?.message}</FormErrorMessage>}
            </FormControl>

            <FormControl isInvalid={Boolean(errors?.street)} w="full">
              <Input placeholder="Logradouro" w="full" {...register("street")} />
              {errors?.street && <FormErrorMessage>{errors.street?.message}</FormErrorMessage>}
            </FormControl>

            <FormControl isInvalid={Boolean(errors?.district)} w="full">
              <Input placeholder="Bairro" w="full" {...register("district")} />
              {errors?.district && <FormErrorMessage>{errors.district?.message}</FormErrorMessage>}
            </FormControl>

            <HStack spacing={6} align="flex-start" w="full">
              <FormControl isInvalid={Boolean(errors?.number)} w={40} isDisabled={withoutNumber}>
                <Input placeholder="Número" w="full" {...register("number")} />
                {errors?.number && <FormErrorMessage>{errors.number?.message}</FormErrorMessage>}
              </FormControl>

              <FormControl isInvalid={Boolean(errors?.complement)} flexGrow={1}>
                <Input placeholder="Complemento" {...register("complement")} />
                {errors?.complement && (
                  <FormErrorMessage>{errors.complement?.message}</FormErrorMessage>
                )}
              </FormControl>
            </HStack>

            <Controller
              control={control}
              name="withoutNumber"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  value=""
                  onChange={onWithoutNumberChange}
                  isChecked={field.value}
                >
                  Sem número
                </Checkbox>
              )}
            />

            <HStack spacing={6} align="flex-start" w="full">
              <FormControl isReadOnly>
                <Input placeholder="Cidade" w="full" {...register("city")} />
              </FormControl>

              <FormControl w={40} isReadOnly>
                <Input placeholder="Estado" w="full" {...register("state")} />
              </FormControl>
            </HStack>

            <Button type="submit" size="lg" disabled={searching || geocoding} alignSelf="flex-end">
              Confirmar
            </Button>
          </VStack>
        </TabPanel>

        <TabPanel padding="0" paddingBottom="4">
          {coordinates && (
            <MapLocationSelector
              height="calc(min(85vh, 600px))"
              coordinates={coordinates}
              onConfirm={onConfirmLocation}
              onCancel={onCancelLocation}
            />
          )}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
