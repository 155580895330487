import {
  Box,
  BoxProps,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";

import { LinkButton, Price } from "..";
import { ProductFragment } from "../../graphql/generated/apolloHooks";
import { useAcceptCreditCard, useAcceptPix } from "../../models/chain";
import { formatMoney } from "../../models/money";
import { productPriceAVista } from "../../models/product";

type ProductPrices = Pick<ProductFragment, "__typename" | "pixPrices" | "creditCardPrices">;

const ModalProductPrices = ({
  creditCardPrices,
  pixPrices,
  chainMaxInstallments: _chainMaxInstallments,
  ...props
}: ProductPrices & Omit<ModalProps, "children"> & { chainMaxInstallments: number }) => (
  <Modal {...props}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Formas de pagamento</ModalHeader>
      <ModalCloseButton />
      <ModalBody mb={3}>
        <VStack w="full" spacing={4}>
          {pixPrices.length > 0 && (
            <VStack spacing={2} alignItems="flex-start" w="full">
              <Heading size="sm">Pix</Heading>
              <Text>À vista no Pix por {formatMoney(pixPrices[0].price)}</Text>
            </VStack>
          )}

          {creditCardPrices.length > 0 && (
            <VStack spacing={2} alignItems="flex-start" w="full">
              <Heading size="sm">Cartão de crédito</Heading>
              <Table variant="striped" colorScheme="gray">
                <Tbody>
                  {creditCardPrices.map(({ installments, price, installmentAmount }) => (
                    <Tr key={installments}>
                      <Td px={3} py={2} border="none">
                        {installments === 1
                          ? "À vista"
                          : `${installments}x sem juros de ${formatMoney(installmentAmount)}`}
                      </Td>
                      <Td px={3} py={2} border="none" isNumeric>
                        {formatMoney(price)}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              {/* // Possibly show complete table of installments by total here
              {creditCardPrices.length < chainMaxInstallments && (
                <Text fontSize="sm" color="text.900" px={2}>
                  Observação: Na finalização do pedido, pedidos com maiores valores poderão ter mais
                  opções de parcelamento no cartão de crédito (até {chainMaxInstallments} parcelas).
                </Text>
              )} */}
            </VStack>
          )}
        </VStack>
      </ModalBody>
    </ModalContent>
  </Modal>
);

export const ProductPrices = ({
  product,
  boxProps,
  showPackageBadge = false,
  alignRight = "md",
  chainMaxInstallments,
}: {
  product: ProductPrices;
  boxProps?: BoxProps;
  showPackageBadge?: boolean;
  alignRight?: "sm" | "md" | "lg";
  chainMaxInstallments: number;
}) => {
  const acceptsCreditCard = useAcceptCreditCard();
  const acceptsPix = useAcceptPix();
  const isPackage = product.__typename === "BundleProduct";
  const { isOpen, onClose, onOpen } = useDisclosure();
  const modalSize = useBreakpointValue({ base: "full", md: "lg" });

  return (
    <Box {...boxProps}>
      <Price
        price={productPriceAVista(product)}
        showPackageBadge={isPackage && showPackageBadge}
        rootProps={{
          height: {
            base: "auto",
            [alignRight]: "4.475rem",
          },
          align: { base: "flex-start", [alignRight]: "flex-end" },
        }}
      />
      <Flex flexDirection="row" marginTop={2.5} justify={{ base: "flex-start", lg: "flex-end" }}>
        {acceptsPix && (
          <Text fontSize="sm" lineHeight="shorter" whiteSpace="nowrap" color="text.900">
            à vista no PIX
          </Text>
        )}

        {acceptsPix && acceptsCreditCard && (
          <Text fontSize="sm" lineHeight="shorter" color="text.900" ml={1} mr={1}>
            ·
          </Text>
        )}

        {acceptsCreditCard && (
          <>
            <LinkButton
              color="text.700"
              lineHeight="shorter"
              onClick={e => {
                e.preventDefault();
                onOpen();
              }}
            >
              outras opções
            </LinkButton>

            <ModalProductPrices
              {...product}
              chainMaxInstallments={chainMaxInstallments}
              size={modalSize}
              isOpen={isOpen}
              onClose={onClose}
            />
          </>
        )}
      </Flex>
    </Box>
  );
};
