export const formatNumber = (
  number: string | number,
  decimals: number = 2,
  thousands: string = ".",
  decimal: string = ","
) => {
  const [a, b] = parseFloat(typeof number === "number" ? number.toString() : number)
    .toFixed(decimals)
    .split(".");
  const formatted = [a.replace(/\B(?=(\d{3})+(?!\d))/g, thousands), b].join(decimal);
  return decimals > 0 ? formatted : formatted.replace(decimal, "");
};
