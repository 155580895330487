import { EffectCallback, useEffect, useRef, useState } from "react";

import { usePrevious } from "react-use";

export * from "./array";

// Execute fn only once when condition is true
export const useEffectOnceCondition = (condition: boolean, fn: EffectCallback) => {
  const executed = useRef(false);

  return useEffect(() => {
    if (!executed.current && condition) {
      executed.current = true;
      return fn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition]);
};

export const copyToClipboard = async (text: string) => {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
};

export const useWhenChanged = <T>(
  value: T,
  fn: (value: T, previous: T | undefined) => void,
  deps: Array<any> = [],
  compare: (a: T | undefined, b: T) => boolean = (a, b) => a === b
) => {
  const previous = usePrevious(value);
  useEffect(() => {
    if (!compare(previous, value)) fn(value, previous);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, previous, fn, compare, ...deps]);
};

export const useValidateImageSrc = (src: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setHasError(false);

    const img = new Image();

    img.onload = () => {
      setHasError(false);
      setIsLoading(false);
    };

    img.onerror = () => {
      setHasError(true);
      setIsLoading(false);
    };

    img.src = src;
  }, [src]);

  return {
    isLoading,
    hasError,
  };
};

type GeoCoordinate = { lat: number; lng: number };
// https://en.wikipedia.org/wiki/Haversine_formula
// Returns the distance between two geo coordinates in meters
export const haversineDistance = (p1: GeoCoordinate, p2: GeoCoordinate) => {
  const { asin, cos, pow, sin, sqrt, PI } = Math;
  const rad = (n: number) => (n * PI) / 180;
  const hav = (n: number) => pow(sin(n / 2), 2);

  const p1lat = rad(p1.lat);
  const p2lat = rad(p2.lat);
  const p1lng = rad(p1.lng);
  const p2lng = rad(p2.lng);

  const ht = hav(p2lat - p1lat) + cos(p1lat) * cos(p2lat) * hav(p2lng - p1lng);

  const earthRadius = 6378137;
  return 2 * earthRadius * asin(sqrt(ht));
};
