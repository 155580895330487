import { Icon, IconProps, useColorModeValue } from "@chakra-ui/react";

export const EloIcon = (props: IconProps) => {
  const color = useColorModeValue("black", "#E9E9E9");

  return (
    <Icon viewBox="0 0 66 24" {...props}>
      <path
        d="M10.3095 5.29231C11.0069 5.05641 11.7556 4.93333 12.5351 4.93333C15.9402 4.93333 18.7813 7.35384 19.4274 10.5641L24.2479 9.57949C23.1402 4.12308 18.3197 0.0102539 12.5351 0.0102539C11.212 0.0102539 9.94023 0.225639 8.75049 0.625639L10.3095 5.29231Z"
        fill="#FFF100"
      />
      <path
        d="M4.61719 20.9231L7.87873 17.2308C6.42232 15.9385 5.5095 14.0615 5.5095 11.959C5.5095 9.86666 6.42232 7.97948 7.87873 6.69743L4.61719 3.01538C2.1454 5.21025 0.586426 8.4 0.586426 11.9692C0.586426 15.5282 2.1454 18.7282 4.61719 20.9231Z"
        fill="#00A3DF"
      />
      <path
        d="M19.4274 13.3744C18.771 16.5847 15.9402 18.9949 12.5351 18.9949C11.7556 18.9949 11.0069 18.8718 10.2992 18.6359L8.74023 23.3026C9.92998 23.7026 11.212 23.918 12.5351 23.918C18.3197 23.918 23.1402 19.8154 24.2479 14.359L19.4274 13.3744Z"
        fill="#EE4023"
      />
      <path
        d="M40.3094 16.6769C39.5094 17.4564 38.4324 17.9282 37.2427 17.9077C36.4222 17.8974 35.6632 17.6513 35.0273 17.241L33.4273 19.7846C34.5247 20.4718 35.8068 20.882 37.2017 20.9026C39.2222 20.9333 41.0683 20.1333 42.4119 18.8308L40.3094 16.6769ZM37.4171 6.30769C33.3965 6.24615 30.0735 9.46666 30.0119 13.4872C29.9914 14.9949 30.4222 16.4102 31.1914 17.5795L44.4017 11.9282C43.6632 8.75897 40.8324 6.36922 37.4171 6.30769ZM33.0376 14.0615C33.0171 13.8974 33.0068 13.7231 33.0068 13.5487C33.0478 11.1795 34.9965 9.28204 37.3658 9.32307C38.6581 9.34358 39.8068 9.9282 40.576 10.8513L33.0376 14.0615ZM48.5555 3.02563V17.1077L50.9965 18.1231L49.8376 20.9026L47.417 19.8974C46.8735 19.6615 46.5042 19.3026 46.2273 18.8923C45.9606 18.482 45.7555 17.9077 45.7555 17.1487V3.02563H48.5555Z"
        fill={color}
      />
      <path
        d="M57.3657 9.53851C57.7965 9.39492 58.2478 9.32312 58.7298 9.32312C60.8119 9.32312 62.535 10.8 62.935 12.759L65.8786 12.1539C65.2016 8.82056 62.258 6.31799 58.7298 6.31799C57.9196 6.31799 57.1401 6.45133 56.4221 6.68722L57.3657 9.53851ZM53.8888 19.0667L55.8785 16.8206C54.9862 16.0308 54.4324 14.8821 54.4324 13.6C54.4324 12.318 54.9965 11.1693 55.8785 10.3898L53.8888 8.14364C52.3811 9.47697 51.4272 11.4359 51.4272 13.6103C51.4272 15.7847 52.3811 17.7334 53.8888 19.0667ZM62.935 14.4718C62.535 16.4308 60.8016 17.9077 58.7298 17.9077C58.258 17.9077 57.7965 17.8257 57.3657 17.6821L56.4119 20.5334C57.1401 20.7795 57.9196 20.9129 58.7298 20.9129C62.258 20.9129 65.2016 18.4103 65.8786 15.077L62.935 14.4718Z"
        fill={color}
      />
    </Icon>
  );
};
