import { useMemo } from "react";

import { useRouter } from "next/router";

import { useIsMobile } from "../../lib/media-query";
import { useEffectOnceCondition } from "../../utils";
import { useIsAuthenticated } from "../auth";
import { useFullCart } from "../cart";
import { ROUTES } from "./routes";

const { INDEX, CART } = ROUTES;

// Removes the url query string (everything after and including the "?")
const removeQueryStringFormPath = (asPath: string) => asPath.replace(/\?.*$/, "");

export const useRedirectCartSteps = () => {
  const { data, loading: loadingCart } = useFullCart();
  const { isAuthenticated, loading: isAuthenticatedLoading } = useIsAuthenticated();
  const { date, time, service, clinic, patientItems, items } = data ?? {};
  const { asPath: pathWithQuery, replace } = useRouter();
  const asPath = removeQueryStringFormPath(pathWithQuery);

  const redirectTo = useMemo(() => {
    if (!loadingCart) {
      if (items && items.length === 0) return INDEX;
      if (!service) return CART.INDEX;

      if (asPath === CART.SELECT_CLINIC) return;
      if (!clinic) return CART.SELECT_CLINIC;

      if (asPath === CART.SELECT_DATE) return;
      if (!date || !time) return CART.SELECT_DATE;

      if (
        (
          [CART.LOGIN, CART.REGISTER, CART.FORGOT_PASSWORD, CART.RESET_PASSWORD] as string[]
        ).includes(asPath)
      )
        return;
      if (!isAuthenticatedLoading && !isAuthenticated) return CART.REGISTER;

      if (asPath === CART.SELECT_VACCINATED) return;
      if (patientItems && patientItems.some(item => !item.patient)) return CART.SELECT_VACCINATED;
    }
  }, [
    loadingCart,
    items,
    service,
    asPath,
    clinic,
    date,
    time,
    isAuthenticatedLoading,
    isAuthenticated,
    patientItems,
  ]);

  useEffectOnceCondition(Boolean(redirectTo), () => {
    replace(redirectTo as NonNullable<typeof redirectTo>);
  });
};

const isCartCheckoutRoute = (path: string) =>
  (
    [
      ROUTES.CART.INDEX,
      ROUTES.CART.SELECT_DATE,
      ROUTES.CART.SELECT_VACCINATED,
      ROUTES.CART.SELECT_CLINIC,
      ROUTES.CART.REVIEW,
      ROUTES.CART.LOGIN,
      ROUTES.CART.FORGOT_PASSWORD,
      ROUTES.CART.RESET_PASSWORD,
      ROUTES.CART.REGISTER,
      ROUTES.CART.PAYMENT_METHOD,
    ] as string[]
  ).includes(path);

export const useCartDrawerProps = () => {
  const { asPath: path } = useRouter();
  const isMobile = useIsMobile();

  const hideCart =
    (isCartCheckoutRoute(removeQueryStringFormPath(path)) && !isMobile) ||
    path === ROUTES.CART.INDEX ||
    path === ROUTES.CART.REVIEW ||
    path === ROUTES.PROFILE ||
    path.startsWith(ROUTES.APPOINTMENTS);

  const readOnly = path.startsWith(ROUTES.CART.INDEX) && path !== ROUTES.APPOINTMENTS;

  return { visible: !hideCart, readOnly };
};
