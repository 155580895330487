import { ReactNode } from "react";

import { Stack, StackProps, Text, VStack } from "@chakra-ui/react";

type InfoCellProps = {
  title: string;
  description?: ReactNode | string;
  spacing?: string;
  rootProps?: StackProps;
};

export const InfoCell = ({ title, description, spacing, rootProps }: InfoCellProps) => (
  <VStack spacing={spacing ?? { base: 1, md: 2 }} align="start" {...rootProps}>
    <Text fontSize="sm" as="h4" color="text.700">
      {title}
    </Text>
    {typeof description === "string" ? <Text color="text.900">{description}</Text> : description}
  </VStack>
);

export const InfoCellList = ({ direction = "column", ...props }: StackProps) => (
  <Stack
    direction={{ base: direction === "column" ? "column" : "row", lg: "row" }}
    spacing={direction === "column" ? { base: 4, lg: 6 } : { base: 6, lg: 4 }}
    {...props}
  />
);
