import { intersection, pipe, prop } from "remeda";

import {
  CartQuery,
  MinimumProductFragment,
  ProductFragment,
  ProductsAvailabilityFromClinicQuery,
} from "../../graphql/generated/apolloHooks";
import { removeAccents, removeSpecialCharacters, replace, toLower, trim } from "../../utils/string";

export const getProductAvailableDates = (
  product: NonNullable<ProductsAvailabilityFromClinicQuery["chain"]>["products"][number],
  cartItem: NonNullable<CartQuery["cart"]>["items"][number] | undefined
) => {
  const productAmount = cartItem?.quantity ?? -1;
  const productDates = product.availableDates
    .filter(({ maxQuantity }) => productAmount <= maxQuantity)
    .map(({ date }) => date);

  return productDates;
};

export const getProductsAvailableDates = (
  products: NonNullable<ProductsAvailabilityFromClinicQuery["chain"]>["products"],
  cartItems: NonNullable<CartQuery["cart"]>["items"]
) => {
  return products.reduce((availableDates, product) => {
    const productDates = getProductAvailableDates(
      product,
      cartItems.find(item => item.product.id === product.id)
    );
    return availableDates ? intersection(availableDates, productDates) : productDates;
  }, undefined as undefined | string[]) as string[];
};

export const productPriceAVista = (
  product: Pick<ProductFragment, "creditCardPrices" | "pixPrices">
) => parseFloat((product.pixPrices[0] ?? product.creditCardPrices[0]).price);

export const productUrlSlug = (product: Pick<ProductFragment, "name">) =>
  pipe(
    product.name,
    trim,
    removeAccents,
    removeSpecialCharacters,
    toLower,
    string => replace(string, " ", /[ ]{2,}/g),
    string => replace(string, "-", / /g)
  );

export const productDescription = (product: MinimumProductFragment): string => {
  switch (product.__typename) {
    case "VaccineProduct":
      return product.manufacturers.map(prop("name")).join(", ");

    case "BundleProduct":
      return product.shortDescription ?? "";

    default:
      return "";
  }
};
