import { anyPass, createPipe, find, map, pipe } from "remeda";

export const coordinates = (coords: google.maps.LatLng) => ({
  lat: coords.lat(),
  lng: coords.lng(),
});

const includesAny = (items: any[]) =>
  anyPass(map(items, (item: any) => (items: any[]) => items.includes(item)));

const addressComponent = (types: string[]) => (a: google.maps.places.PlaceResult) =>
  pipe(
    a,
    a => a.address_components!,
    find((a: google.maps.GeocoderAddressComponent) => includesAny(types)(a.types))
  )!;

const shortAddressComponent = (types: string[]) => (a: google.maps.places.PlaceResult) =>
  pipe(a, addressComponent(types), (a: google.maps.GeocoderAddressComponent) => a?.short_name);

const longAddressComponent = (types: string[]) => (a: google.maps.places.PlaceResult) =>
  pipe(a, addressComponent(types), a => a?.long_name as string | undefined);

export const placeCountry = longAddressComponent(["country"]);

export const placeCountryAbbreviation = shortAddressComponent(["country"]);

export const placeState = longAddressComponent(["administrative_area_level_1"]);

export const placeStateAbbreviation = shortAddressComponent(["administrative_area_level_1"]);

export const placeCity = longAddressComponent(["administrative_area_level_2"]);

export const placeDistrict = longAddressComponent(["sublocality_level_1", "sublocality"]);

export const placeStreet = longAddressComponent(["street_name", "route"]);

export const placeStreetNumber = longAddressComponent(["street_number"]);

export const placeZipCode = createPipe(longAddressComponent(["postal_code"]), zipCode =>
  zipCode?.replace(/\D/g, "")
);

export const placeCoordinates = (a: google.maps.places.PlaceResult) =>
  pipe(a, a => a.geometry!.location!, coordinates);

export const placeAddress = (a: google.maps.places.PlaceResult) => ({
  country: placeCountry(a)!,
  countryAbbreviation: placeCountryAbbreviation(a),
  state: placeState(a)!,
  stateAbbreviation: placeStateAbbreviation(a),
  city: placeCity(a)!,
  district: placeDistrict(a)!,
  street: placeStreet(a)!,
  number: placeStreetNumber(a),
  zipCode: placeZipCode(a),
});

export const predictionMainText = (prediction: google.maps.places.AutocompletePrediction) =>
  prediction.structured_formatting.main_text;

export const predictionSecondaryText = (prediction: google.maps.places.AutocompletePrediction) =>
  prediction.structured_formatting.secondary_text;
